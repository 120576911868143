@import url("https://use.typekit.net/fvk4nfp.css");

@font-face {
  font-family: "Butler"; font-weight: 900; font-style: normal;
  src: url("./Assets/Fonts/Butler-Black.woff2") format("woff2"),
    url("./Assets/Fonts/Butler-Black.woff") format("woff");
}

@font-face {
  font-family: "Butler"; font-weight: bold; font-style: normal;
  src: url("./Assets/Fonts/Butler-Bold.woff2") format("woff2"),
    url("./Assets/Fonts/Butler-Bold.woff") format("woff");
}

@font-face {
  font-family: "Butler"; font-weight: 800; font-style: normal;
  src: url("./Assets/Fonts/Butler-ExtraBold.woff2") format("woff2"),
    url("./Assets/Fonts/Butler-ExtraBold.woff") format("woff");
}

@font-face {
  font-family: "Butler"; font-weight: normal; font-style: normal;
  src: url("./Assets/Fonts/Butler.woff2") format("woff2"),
    url("./Assets/Fonts/Butler.woff") format("woff");
}

// $color1: #4389C9; prev color
$color1: #F7D54E;
$color1opacity30: #4389C930;
$color1opacity42: #4389C942;
$color1opacity60: #4389C960;
$fontcolor: #414042;

$colorbg: #202945;

// $color2: #1EA74A; prev color
$color2: #5BB056;


$color3: #444444;

$primaryfont: "Lato", sans-serif;

.mui-list-item.Mui-selected { background-color: #20294544 !important; }

body { background: $colorbg; font-family: "Lato", sans-serif; width: 100%; margin: 0; overflow-x: hidden; }

header {
  & { box-sizing: border-box; margin: 0px auto 30px; padding: 45px 15px; display: flex; align-items: center; justify-content: space-between; max-width: 1150px; width: 100%; }

  .logo-left { & { display: flex; flex-direction: row; align-self: center; }
    img { height: 45px; width: auto; align-self: center; padding: 5px 15px 5px 5px; }
  }
  .logo-right { & { }
    img { width: auto; height: 58px; position: relative; align-self: center; }
  }
  .logo { 
    & { position: absolute; left: 100px; cursor: default; }
    img { max-height: 80px; max-width: 129px; }
  }
  .assessment-title {
    & { font-weight: 700; font-size: 35px; line-height: 42px; text-align: center; letter-spacing: 3.5px; text-transform: capitalize; color: #ffffff; text-align: center; font-family: $primaryfont; margin: 0 auto; width: auto; }
    p { font-weight: 700; font-size: 28px; line-height: 34px; margin: 0 auto; color: $colorbg; align-self: center; }
    img { height: auto; width: 46px; margin: 0 auto; margin: 0 0 0 auto; align-self: center; }
  }
}

main {
  .container { margin: 0 auto 2rem; max-width: 1110px; border-radius: 10px; width: 95%; box-sizing: border-box; }
  .loading { display: flex; justify-content: center; align-items: center; }
  
  .video { 
    & { position: relative; width: 50%; height: 100%; display: flex; justify-content: center; object-fit: cover; transition: all 0.25s; overflow: hidden; }
    video { object-fit: cover; transform: scale(100%); max-width: 100%;  }
    .thumbnail { position: absolute; width: auto; height: 100%; overflow: hidden; object-fit: cover; }
  }
  // .video:hover {
  //   .playbtn { transform: translate(-50%, -50%) scale(1.05); backdrop-filter: blur(0px); border: 1px solid #00558C55; box-shadow: 0 0 100px 50px #55555577; }
  // }

  .start { 
    & { height: 580px; background-color: #202945; display: flex; justify-content: center; border-radius: 0px 10px 10px 0px;}
    .video { 
      & { position: relative; width: 50%; height: 100%; display: flex; justify-content: center; object-fit: cover; transition: all 0.25s; background-color: #202945; border-radius: 10px 0 0 10px; overflow: hidden; } // justify-content: center; background: #333; overflow: hidden; }
      video { object-fit: cover; transform: scale(100%); max-width: 100%; }
      
      .playbtn { position: absolute; top: 85%; left: 50%; transform: translate(-50%, -50%); cursor: pointer; height: auto; width: 190px; }
      .thumbnail { position: absolute; height: 580px; width: 100%; overflow: hidden; object-fit: cover; }
      .owner-details {
        & { width: 100%; height: 100px; position: absolute; bottom: 0; display: flex; flex-direction: column; align-items: center; justify-content: center; background: #00000080; }
        .name {
          & { margin: 0; font-weight: 700; font-size: 24px; color: #F7D54E; }
        }
        .position {
          & { margin: 0; font-weight: 600; font-size: 20px; color: #ffffff; }
        }
        .company {
          & { margin: 0; font-weight: 600; font-size: 20px; color: #ffffff; }
        }
        .name-mobile {
          & { display: none; font-weight: 700; font-size: 20px; color: #F7D54E; }
        }
        .position-mobile {
          & { display: none; font-weight: 700; font-size: 16px; color: #ffffff; }
        }
        .company-mobile {
          & { display: none; font-weight: 700; font-size: 16px; color: #ffffff; }
        }
      }

    }
    .start-img-mobile { display: block; margin: 0 auto; }
    .start-img-desktop { display: block; margin: 0 auto; max-width: 100%; height: auto; }

    .intro {
      & { background-color: #FFFFFF; padding: 0 4%; display: flex; flex-direction: column; justify-content: center; width: 42%; transition: all 0.25s; border-radius: 0 10px 10px 0; overflow: hidden; } // width: 38%; }
      h1 { margin: 0; font-family: "lato", sans-serif; color: #343333; width: auto; margin-top: 42px; margin-top: 15px; font-style: normal; font-weight: 700; font-size: 38px; line-height: 65px; }
      h1 br { content: ""; }
     
     
      // h2 { margin: 0; margin-bottom: 0; font-weight: 300; font-size: 24px; line-height: 35px; color: $fontcolor; width: auto; max-width: 34ch; }
      // .completion { 
      //   & { display: flex; align-items: center; margin-top: 68px; color: #343333; font-weight: 700; font-size: 18px; }
      //   .cta { display: block; background: linear-gradient(180deg, #77A07C 0%, rgba(103, 143, 157, 0.97) 100%); border-radius: 5px; color: #fff; text-decoration: none; width: 193px; text-align: center; font-family: "lato", sans-serif; font-size: 21px; font-weight: 700; text-transform: uppercase; padding: 12px 0 12px; margin-left: 0; }
      //   .cta:hover { background: $color2; color: #fff; }
      //   .time-estimate {
      //     & { margin: 15px auto 15px 60px; }
      //     .clock-icon { width: 25px; height: 25px; vertical-align: bottom; }
      //   }
      // }
      .intro-points { & { margin-top: 34px; margin-bottom: 15px; font-family: "Lato"; font-style: normal; font-weight: 300; font-size: 24px; line-height: 35px; color: #343333; }
          p { margin-top: 0; margin-bottom: 0;}
			}

      .completion { 
        & { display: flex; align-items: center; color: #343333; font-weight: 700; font-size: 18px; }
        .btn-start { display: block; background-color: $color1; color: #343333; border-radius: 5px; text-decoration: none; text-align: center; font-family: $primaryfont; font-size: 21px; font-weight: 700; line-height: 25px; letter-spacing: 1px; text-transform: uppercase; padding: 12px 25px 12px; margin-left: 0; }
        .btn-start:hover { background-color: $colorbg; transition: 0.3s; color: #fff; }
        .time-estimate {
          & { margin: 15px auto 15px 45px; }
          .clock-icon { width: 25px; height: 25px; vertical-align: bottom; }
        }
      }

			.time-estimate { 
      & { width: fit-content; display: flex; align-items: center; padding: 30px 0; }
			.clock-icon { width: 16px; height: 16px; vertical-align: bottom; }
      span { margin-left: 6px; height: 35px; display: inline-block; font-weight: 400; font-size: 18px; line-height: 35px; }
      .btn-start {display: flex; color: #343333;text-decoration: none; background-color: #f7d54e; border: none; height: 50px; border-radius: 5px; font-family: "Lato"; font-style: normal; font-weight: 700; font-size: 21px; line-height: 25px;
					letter-spacing: 1px; width: 193px; padding: 0px; margin-right: 50px; transition: 0.25s; cursor: pointer; justify-content: center; align-items: center;
        }
      .btn-start:hover {background: #202945; color: #fff;}
			}
    }
  }

  .how-it-works {
    background-color: #fff; display: flex;
    // display: grid; grid-template-columns: repeat( auto-fit, minmax(474px, auto) );
    justify-content: space-evenly; margin: 0 auto 2rem; max-width: 1110px; border-radius: 10px;
    .info-text-mobile { display: none; }
    .how-it-works-tiles {
      & { display: grid; grid-template-columns: repeat(3, 1fr); align-self: center; max-width: 460px; max-height: 460px; margin: 3rem 0.5rem; }
      .how-it-works-btn { font-size: 1.15rem; font-weight: 700; height: 145px; width: 145px; margin: 0.25rem; padding: 6px; background-color: #3884C6; color: #fff; border: none; border-radius: 5px; font-family: "lato", sans-serif; cursor: pointer; }
      .active { background-color: $color2 !important; }
    }
    .how-it-works-explanation {
      display: grid; grid-template-rows: 2fr 0.5fr; align-self: center; width: 45%; text-align: left; margin: 1rem 1rem 1rem 0.5rem; position: relative; // max-width: 50ch;
      
      .explanation-container { max-width: 46ch; }
      h2 { font-size: 3rem; margin: 1rem auto; line-height: 65px; letter-spacing: 0px; }
      // p:first-child { margin-top: -2rem; }
      p { font-size: 1.5rem; font-weight: 300; margin: 2rem 0; }
      .more-info { display: inline-block; color: #5DB256; }
      .test-btn { display: inline-block; padding: 1rem; margin: 0 auto; font-size: 21px; font-weight: bold; text-decoration: none; text-transform: uppercase; color: #fff; background: linear-gradient(270deg, $color2 0%, $color1 100%); border-radius: 5px; letter-spacing: 1px;
        &:hover { background: $color2; transition: 0.0s; color: #fff; }
      }
      .info-text-desktop { font-size: 16px; font-style: italic; padding: 0 0.25rem 0 0; margin: 0.5rem 0 0; align-self: end; font-weight: 400; position: absolute; bottom: 18px; }
    }
  }

  .assessment {
    & { min-height: 580px; background: #fff; }
    .steps {
      & { margin: 0 auto 50px; width: 80%; min-height: 480px; }
      .spacer { height: 0px; }

      .progress-bar {
        & { display: flex; justify-content: space-between; width: 100%; }
        .bar-section { 
          .progress-percentage { text-align: right; font-weight: 700; font-size: 1.1rem; margin: 1rem 0 0.5rem 2rem; }
          .progress-grid { 
            display: grid; grid-template-columns: repeat(3, 1fr); max-width: 42px; margin: 2.5rem auto;
            .grid-square { width: 10px; height: 10px; margin: 2px; } 
          }
          .bar {&{height: 6px; margin: 0 5px 10px;}
          
        }
          .section-title { width: 100%; text-align: center; font-family: 'lato', sans-serif; font-weight: 400; color: #343333; font-size: 21px; }
        }

        .MuiLinearProgress-root.custom-bar { background-color: #E9E9E9; height: 6px; border-radius: 3px; }
        .MuiLinearProgress-bar { background: #F7D54E; }
      }

      .progress-indicator { margin: 70px auto 50px; width: 100%; text-align: center; font-family: $primaryfont, sans-serif; font-weight: 400; color: #202945; font-size: 18px; }

      .question { min-height: 160px; max-width: 85%; margin: auto; text-align: center; font-family: $primaryfont, sans-serif; font-weight: 400; color: #343333; font-size: 24px; }
      .question p strong { font-weight: 900; }
      .radio-container {
        & { width: 86%; margin: 0 auto; }
        
        .slider-labels {
          & { margin: 0 auto; font-weight: 400; font-size: 21px; color: #343333; }
          .label {
            & { cursor: pointer; width: 20%; float: left; position: relative; }
            p { display: block; position: absolute; margin-left: -50%; margin-top: -20px; padding-top: 20px; width: 100%; text-align: center; z-index: 1000; }
            p.desktop { display: block; }
            p.mobile { display: none; }
          }
          .active { font-weight: 700; color: rgb(62, 133, 199); }
        }

        .MuiRadio-root { margin-left: -20px; }
        
      }

      .slider-container {
        & { width: 86%; margin: 0 auto; margin-top: 2rem; }
        .slider { width: 100%; position: relative; }
        .slider-points {
          & { margin-top: -20px; width: 125%; font-weight: 300; font-size: 14px; color: #343333; position: relative; }
          .point {
            & { cursor: pointer; width: 12px; height: 12px; border-radius: 8px; margin-left: -8px; margin-top: -5px; background: #F7D54E; position: absolute; z-index: 9; }
          }
        }
        .slider-labels {
          & { width: 125%; font-weight: 400; font-size: 21px; color: #343333; }
          .label {
            & { cursor: pointer; width: 20%; float: left; position: relative; }
            p { display: block; position: absolute; margin-left: -50%; margin-top: -20px; padding-top: 40px; width: 100%; text-align: center; z-index: 1000; }
            p.desktop { display: block; }
            p.mobile { display: none; }
          }
          .active { font-weight: 700; color: $color1; }
        }

        .custom-slider.MuiSlider-root { z-index: 1000; color: $color1; width: calc(100% + 10px); margin-left: -5px; }

        .custom-slider .MuiSlider-rail { opacity: 0; background-image: linear-gradient(180deg, #5DB256 0%, #3A86C0 100%); height: 10px; }
        .custom-slider .MuiSlider-track { border: 0px solid $color1; background-image:#F7D54E; height: 14px; }

        .custom-slider .MuiSlider-thumb { background: #F7D54E; width: 22px; height: 22px; }
        .custom-slider .MuiSlider-thumb:before { background: #F7D54E; border: 3px solid #fff; width: 10px; height: 10px; }
        .custom-slider .MuiSlider-thumb.Mui-focusVisible { box-shadow: 0px 0px 0px 8px rgba(247, 213, 77, 0.5); }
        .custom-slider .MuiSlider-thumb:hover { box-shadow: 0px 0px 0px 8px rgba(247, 213, 77, 0.5); }
        
        .MuiLinearProgress-root.custom-bar { background-color: #E9E9E9; }
        .custom-bar .MuiLinearProgress-bar { background-color: $color1; top: -1px; }

        // .css-eglki6-MuiLinearProgress-root {
        //   background: #d8d8d8;
        // }
        // .css-5xe99f-MuiLinearProgress-bar1 {
        //   background: $color1;
        // }
        // .css-eg0mwd-MuiSlider-thumb {
        //   background: $color1;
        // }
        // .css-eg0mwd-MuiSlider-thumb:before {
        //   background: #fff;
        //   border: 4px solid $color1;
        //   width: 14px;
        //   height: 14px;
        // }
        // .css-eg0mwd-MuiSlider-thumb.Mui-focusVisible {
        //   box-shadow: 0px 0px 0px 8px rgba(247, 213, 77, 0.5);
        // }
        // .css-eg0mwd-MuiSlider-thumb:hover { box-shadow: 0px 0px 0px 8px rgba(247, 213, 77, 0.5); }
      }

      .slider-none-adjust { height: 35px; }
      .step-buttons {
        & { display: flex; justify-content: space-between; margin: 115px auto 30px; padding-bottom: 2rem; }
        .prev-btn { & { font-family: $primaryfont; color: #343333; font-weight: 600; font-size: 16px; font-family: "lato", sans-serif; padding: 12px 25px; }}
        
         .next-btn{ & { font-family: $primaryfont; width: 100%; max-width: 140px; padding: 12px 25px; font-size: 16px; font-weight: 700; color: #343333; border-radius: 3px; }
          
         }
         .inactive{
            background-color: transparent;
            pointer-events: none;
         }
         .active{
          background-color: #F7D54E;
          pointer-events: all;
         }
        .all-set-btn { & { font-size: 16px; font-weight: 700; line-height: 25px; text-align: center; background: linear-gradient(270deg, $color2 0%, $color1 100%); color: #fff; padding: 12px 25px; text-transform: uppercase; }
          &:hover { background: $color2; transition: 0.0s; color: #fff; }
        }
        
      }
    }
  }

  .halfway {
    & { min-height: 550px; max-width: 1110px; background: #fff; display: flex; flex-direction: column;position: relative;}
    .mid-page-content { display: flex; flex-direction: column; min-height: 500px; justify-content: center; align-items: center; }

    .progress-one-txt { box-sizing: border-box; font-size: 32px; line-height: 50px; text-align: center; color: #202945; width: 100%; max-width: 42ch; padding: 0 15px; }
    .gif{ width: 119px; height: 119px; padding: 0 15px; }

    .completion { 
      & { display: flex; width: 81%; justify-content: space-between; margin: 0 auto; padding: 0 0 2rem 0; }
      .cta { & { font-family: $primaryfont; width: 100%; max-width: 140px; padding: 12px 25px; background-color: #F7D54E; color: #343333; font-size: 16px; border-radius: 3px; font-weight: 700; transition: all 0s; }
        &:hover { background-color: $colorbg; color: #FFFFFF; }
      }
      .back-btn {
        & { font-family: $primaryfont; width: 100%; max-width: 140px; padding: 12px 25px; background-color: transparent; color: #343333; font-size: 16px; border-radius: 3px; }
      }
    }
  }

  .pre-additional-info { & { width: 97%; max-width: 1100px; padding: 15px 30px 30px; margin: 30px auto; box-sizing: border-box; background-color: white; text-align: center; }
    h1 { font-size: 24px; font-weight: 400; margin: 15px auto; padding: 30px 30px 10px; }
    p { font-size: 24px; font-weight: 300; max-width: 65ch; margin: 30px auto; }
    .radio { font-size: 24px; font-weight: 300; max-width: 65ch; margin: 30px auto; }
    .MuiTypography-root {font-size: 20px; font-weight: 300; max-width: 30ch; font-family: "lato", sans-serif;}
    .Mui-checked { color: #F4D62B; }
    .Mui-checked + .MuiTypography-root {font-weight: 600; font-size: 19px;}
    .MuiFormControlLabel-root { width: 23ch; margin: 0 auto;}
  }
  .pre-additional-info, .pre-additional-questions-details, .assessment-additional {
    .step-buttons { & { display: flex; justify-content: space-between; align-items: center; max-width: 85%; margin: 15px auto; padding: 30px 15px; }
      .cta-prev { padding: 11px 25px; color: $fontcolor; font-size: 16px; font-weight: 400; font-family: $primaryfont; }
      .cta { margin: 0; font-size: 16px; font-weight: 700; background-color: $color1; color: #FFFFFF; padding: 11px 25px; font-family: $primaryfont; }
      .cta:hover { background-color: $color2; color: white; }
      // .nextbtn { background: $color1; color: #fff; padding: 14px 26px; border-radius: 35px; font-weight: 700; }
      // .nextbtn:hover { background-color: $color2; }
      // .nextbtn.Mui-disabled { background: #C1C1C1; }
      // .all-set-btn { padding: 14px 2.5rem; }
    }
  }

  .assessment-additional {
    & { min-height: 580px; background: #FBF9F2; border-radius: 10px; color: $fontcolor; }
    .steps {
      & { margin: 0 auto 50px; width: 80%; min-height: 480px; }
      .spacer { height: 50px; }
      h1 {  color: $fontcolor; font-size: 18px; text-align: center; }

      .progress-indicator { margin: 50px auto 50px; width: 100%; text-align: center; font-family: "lato", sans-serif; font-weight: 400; color: #202945; font-size: 18px; }

      .question { min-height: 75px; text-align: center; font-family: "lato", sans-serif; font-weight: 400; color: $fontcolor; font-size: 24px; line-height: 31px; max-width: 45ch; margin: auto; }
      .textarea { min-height: 80px; }
      .textarea-field { max-width: 540px; width: 80%; margin: 0 auto; margin-bottom: 0; }
      .MuiInput-root { background: white; font-family: 'lato', sans-serif !important; font-size: 21px; border: 1px solid #979797; padding: 14px 18px; border-radius: 5px; }
      .form-details-text { background-color: #FBF9F2; padding: 0 9px 22px; height: 90px; }

      .slider-container {
        & { width: 86%; margin: 0 auto; padding: 0 0 4rem 0; }
        .slider { width: 100%; position: relative; }
        .slider-points {
          & { margin-top: -20px; width: 125%; font-weight: 300; font-size: 14px; color: $fontcolor; position: relative; }
          .point {
            & { cursor: pointer; width: 12px; height: 12px; border-radius: 8px; margin-left: -8px; margin-top: -5px; background: #9b9b9b; position: absolute; z-index: 9; }
          }
        }
        .slider-labels {
          & { width: 125%; font-weight: 400; font-size: 21px; color: $fontcolor; }
          
          
          .label {
            p.desktop { display: block; }
            p.mobile { display: none; }
            & { cursor: pointer; width: 20%; float: left; position: relative; }
            p { position: absolute; margin-left: -50%; margin-top: -20px; padding-top: 40px; width: 100%; text-align: center; z-index: 1000; }
          }
          .active { font-weight: 700; color: $color1; }
        }

        .custom-slider.MuiSlider-root { z-index: 1000; color: $color1; }

        .custom-slider .MuiSlider-rail { opacity: 0; }
        .custom-slider .MuiSlider-track { border: 5px solid $color1; }

        .custom-slider .MuiSlider-thumb { background: $color1; width: 22px; height: 22px; }
        .custom-slider .MuiSlider-thumb:before { background: $color1; border: 3px solid #fff; width: 10px; height: 10px; }
        .custom-slider .MuiSlider-thumb.Mui-focusVisible { box-shadow: 0px 0px 0px 8px rgba(247, 213, 77, 0.5); }
        .custom-slider .MuiSlider-thumb:hover { box-shadow: 0px 0px 0px 8px rgba(247, 213, 77, 0.5); }
        
        .MuiLinearProgress-root.custom-bar { background-color: #E9E9E9; }
        .custom-bar .MuiLinearProgress-bar { background-color: $color1; top: -2px; }

        // .css-eglki6-MuiLinearProgress-root {
        //   background: #d8d8d8;
        // }
        // .css-5xe99f-MuiLinearProgress-bar1 {
        //   background: $color1;
        // }
        // .css-eg0mwd-MuiSlider-thumb {
        //   background: $color1;
        // }
        // .css-eg0mwd-MuiSlider-thumb:before {
        //   background: #fff;
        //   border: 4px solid $color1;
        //   width: 14px;
        //   height: 14px;
        // }
        // .css-eg0mwd-MuiSlider-thumb.Mui-focusVisible {
        //   box-shadow: 0px 0px 0px 8px rgba(247, 213, 77, 0.5);
        // }
        // .css-eg0mwd-MuiSlider-thumb:hover { box-shadow: 0px 0px 0px 8px rgba(247, 213, 77, 0.5); }
      }

      .slider-none-adjust { height: 35px; }
      .step-buttons {
        & { display: flex; justify-content: space-between; margin: 3rem auto 3rem; padding-bottom: 30px; padding: 1.5rem 0 2rem 0; }
        button { color: #182036; font-weight: 600; font-size: 16px; font-family: "lato", sans-serif; padding: 11px 26px; }
        .nextbtn { 
          // background: linear-gradient(180deg, #77A07C 0%, rgba(103, 143, 157, 0.97) 100%); 
          &{ background-color: $color1; color: $colorbg; padding: 11px 26px; border-radius: 5px; font-weight: 700; transition: background 0s; font-size: 16px; }
          &:hover{
            color: #fff;
            background-color: #202945;
            transition: 0.3s;
          }
        }
        .nextbtn.go-to-results { &{background-color: $color1;} &:hover{color:#fff; background-color: #202945; transition: 0.3s;} }
        // @media (hover: hover) { .nextbtn:hover, .nextbtn:active { background: $color2; } .nextbtn.go-to-results:hover, .nextbtn.go-to-results:active { background: #678f9d; } }
        // @media (hover: none) { .nextbtn:active { background: $color2; } .nextbtn.go-to-results:active { background: #678f9d; } }
        .nextbtn.Mui-disabled { background: #C1C1C1; }
        .all-set-btn { font-size: 21px; font-weight: 700; padding: 12.5px 20px; line-height: 25px; text-align: center; }
      }
    }
  }

  .details, .pre-additional-questions-details {
    & { min-height: 630px; max-width: 1110px; width: 95%; background-color: #FFF; padding-bottom: 15px; }
    h1 { width: auto; text-align: center; margin: 0 auto; font-family: "lato", sans-serif; font-size: 36px; color: $fontcolor; padding: 32px 15px 0; }
    p { text-align: center; font-size: 21px; font-weight: 400; max-width: 46ch; line-height: 1.4em; color: $fontcolor; padding: 0 15px; margin: 15px auto 0; }

    .form {
      & { display: flex; justify-content: center; flex-wrap: wrap; margin: 30px auto 0px auto; width: 65%; }
      .form-detail { 
        * { box-sizing: border-box; }
        & { position: relative; flex: 0 1 46%; max-width: 46%; display: block; margin-bottom: 20px; font-weight: 700; padding: 0 2%; /* overflow: hidden; */ }
        p { padding: 0; margin-bottom: 5px; text-align: left; font-size: 16px; font-weight: 700; }
        .MuiInput-root { font-family: 'lato', sans-serif !important; font-size: 18px; font-weight: 400; }
        .MuiInput-input { height: 28px; }
        .MuiSelect-select .first-menu { color: #fff; }
        
        // .form-details-text { background-color: #F4F4F4; padding: 15.6px 15px; padding: 10px 15px; max-width: 100%; }
        .form-details-text { background-color: #F4F4F4; max-width: 100%; border-radius: 3px; }
        .form-details-text .MuiInput-root { padding: 10px 15px; border-radius: 3px; border: 1px solid transparent; }
        .form-details-text .MuiInput-root.Mui-focused { border: 1px solid $colorbg; }
        
        
        .form-details-text.react-select div:nth-child(3) { border: none; }
        .form-details-text.react-select .react-select__placeholder { white-space: nowrap; overflow-x: hidden; padding-left: 4px; }
        .form-details-text.react-select { & { padding: 0; font-size: 18px; font-family: "lato", sans-serif; font-weight: 400; }
          div:first-child { padding-top: 6.25px; padding-bottom: 6.25px; }
        }

        .form-details-text input:focus::placeholder { color: transparent; font-weight: 300; }
        .form-details-text {
          input[type=number]::-webkit-inner-spin-button, 
          input[type=number]::-webkit-outer-spin-button { 
            -webkit-appearance: none;
            -moz-appearance: none;
            appearance: none;
          }
        }
        // .error-text { position: absolute; bottom: -20px; color: red; font-weight: 400; font-size: 13px; }
        .error-text { color: red; font-weight: 400; font-size: 13px; margin: 2px 0 0; }
        .required-field { color: $color1; }
        input:-webkit-autofill,
        input:-webkit-autofill:hover, 
        input:-webkit-autofill:focus, 
        input:-webkit-autofill:active{
            -webkit-box-shadow: 0 0 0 30px #F4F4F4 inset !important;
        }
      }
      .form-detail:last-child.school-name {
        flex: 0 1 100%; max-width: 100%; width: 100%;
      }
    }

    .details-buttons { & { display: grid; grid-template-columns: 1fr 1fr 1fr; max-width: 80%; margin: 0 auto; padding-top: 16px; padding-bottom: 3rem; } 
      .back-btn { & { align-self: center; } button { color: #444444; padding: 10px 15px; }}
      .cta-btn {
        & { margin: 0 auto; text-decoration: none; align-self: center; }
        a { text-decoration: none; }
        button { width: 280px; background: $color1; font-family: "lato", serif; font-size: 21px; font-weight: 700; color: #fff; border-radius: 3px; padding: 8px 0; text-align: center; text-transform: uppercase; }
      button:hover { background: $color2; transition: 0.3s; color: #fff; }
      .button-progress { margin: 4px 0 -2px; }
    }
    }
  }
  .details .details-container .buttons { & { display: grid; grid-template-columns: 0.25fr 1fr 0.25fr; max-width: 90%; margin: 30px auto 10px; }
    .back-btn { align-self: center; }
    .show-results { & { margin: 0 auto; }
      button {
        & {color: $colorbg; background-color: $color1; font-family: $primaryfont; font-weight: 700; font-size: 21px; padding: 12px 25px; }
        &:hover{
          background-color:#202945;
          color: #FFF;
        }
      }
    }
  }

  .results {
    & { min-height: 500px; background: #fff; }

    .selection-form { 
      &  { width: 50%; display: flex; align-items: center; margin: 20px auto; flex-wrap: wrap; justify-content: center; text-align: center; font-size: 36px; font-weight: 400; }
      .form-detail { flex: 0 1 100%; margin-right: 4%;}
      .form-text { flex: 0 1 100%; }
      .form-details-text { 
        & { background-color: #fff; border: 0px solid #efefef; border-bottom: 4px solid $color1; padding: 3px 7px; max-width: 90%; }
        .MuiInput-root { font-size: 36px; font-family: "lato", sans-serif; }
      }
    }

    h1 { width: auto; max-width: 42ch; text-align: center; margin: 0 auto; font-family: 'lato', sans-serif; font-size: 36px; color: #414042; padding: 30px 15px 0; }
    
    .progress-bar { margin: 80px auto 40px; }
    .single-group_assessment #group-sections .si_ass_result { padding: 7% 0px 3%; }
    span.msi_name { color: #d3202b; font-size: 18px; font-weight: 900; font-family: "Avenir", Sans-serif; }
    .pro_top_details span { display: block; line-height: 30px; min-width: 180px; }
    .pro_top_details{ position: absolute; bottom: 45px; left: 0; max-width: 130px; width:100%; -webkit-transition: all .5s ease-in-out; -moz-transition: all .5s ease-in-out; -o-transition: all .5s ease-in-out; transition: all .5s ease-in-out; }
    .in-ass-result .si_ass_progress span, .single-assesment .si_ass_progress span { position: absolute; font-size: 30px; bottom: 42px; left: 0; font-weight: bold; color: #4A4A4A; font-family: "Aleo", Sans-serif; }
    .si_ass_progress:before {  }
    .single-group_assessment #group-sections .si_ass_progress:before { height: 45px; }

    .si_ass_progress { position: relative; max-width: 700px; height: 26px; margin: 0 auto; border-radius: 12px; width: 100%; background-image: linear-gradient(to right, #cc2c24, #ffca26 62%, #429653 100%); }
    .si_ass_progress .progress-line { width: 2px; height: 55px; content: ' '; background: #4a4a4a; left: 0; bottom: 0; position: absolute; -webkit-transition: all .5s ease-in-out; -moz-transition: all .5s ease-in-out; -o-transition: all .5s ease-in-out; transition: all .5s ease-in-out; }
    .si_ass_progress .msi_score { display: block; position: absolute; font-size: 30px; font-weight: bold; color: #333333; bottom: 60px; left: 0; margin-left: -20px; }
    
    .pervalue h4 { position: relative; }
    .pervalue { max-width: 700px; margin: -18px auto; display: flex; clear: both; color: #333333; font-size: 16px; }
    .pervalue h4.nimp:before { content: ''; position: absolute; left: 0; }
    .pervalue h4.nimp:after { content: ''; position: absolute; right: -19px; }
    .pervalue h4.mexpe:after { content: ''; position: absolute; right: -19px; }
    .pervalue h4.eexpe:after { content: ''; position: absolute; right: 0; }

    .si_pro_header { max-width: 700px; margin: 10px auto 0; display: flex; clear: both; }
    .si_pro_header h4 { position: relative; font-size: 16px;  font-weight: 400; text-align: center; color: #333333; text-transform: capitalize; margin-top: 10px; }
    .si_pro_header h4.nimp:before { width: 2px; height: 28px; content: ' '; background: #fff; position: absolute; right: 0; bottom: 71px; }
    .si_pro_header h4.mexpe:before { width: 2px; height: 28px; content: ' '; background: #fff; bottom: 71px; position: absolute; right: 0; }
    .si_pro_header h4 span { color: #D3202B; line-height: 35px; font-size: 16px; }
    
    .result-text { & { text-align: center; font-size: 21px; color: #414042; background-color: #F4F4F4; padding: 21px 30px; margin: 45px auto 15px; box-sizing: border-box; width: 90%; max-width: 860px; }
      p { margin: 15px auto 15px; text-align: left; }
    }
    .result-sections { 
      // & { margin: 50px 5%; display: flex; justify-content: center; flex-wrap: wrap; }
      & { margin: 30px 5%; display: block; }
      .score-section { 
        & { flex: 0 1 25%; text-align: center; color: #333333; font-weight: 400; font-size: 18px; margin: 10px auto; }
        .score-txt { font-size: 18px; font-weight: 700; margin-bottom: 28px; }
        .score-slider { width: 80%; margin: 0 auto; margin-bottom: 16px; }
      }
    }
    .pdf-result-sections { display: none; }

    .MuiLinearProgress-root.custom-bar { background-color: #E9E9E9; height: 10px; border-radius: 1px; }
    .custom-bar .MuiLinearProgress-bar { background-color: #1EA74A; }
    
    .selection-form .form-details-text .MuiInput-root .MuiSelect-select { padding-right: 0; }
    //.css-eglki6-MuiLinearProgress-root { background: #E9E9E9; height: 5px; }
    //.css-5xe99f-MuiLinearProgress-bar1 { background: $color1; height: 5px; }

    .members-answered {
      & { max-width: calc(880px - 10%); background: #FBFBFB; margin: 20px auto; padding: 0px 0% 0px; font-family: "lato", sans-serif; font-size: 18px; color: #414042; text-align: left; border-radius: 8px; }
      .section {
        & { background-color: #678F9D; color: #fff; padding: 10px 17px; margin: 0; font-weight: 700; border-top-left-radius: 3px;  border-top-right-radius: 3px; display: flex; align-items: center; justify-content: space-between; cursor: pointer; }
        .section-toggle { display: block; cursor: pointer; height: 24px; }
      }
      .answered-detail { 
        & { font-weight: 300; font-size: 18px; color: #000; padding: 10px 30px; width: calc(100% - 60px); display: flex; align-items: center; }        
        .answer { flex: 1 1 285px; margin-left: 10px; text-align: right; font-weight: 700; }
        p { margin: 0; }
        .true { color: #73B76F; }
        .neutral { color: #FFB545; }
        .false { color: #FF6645; }
      }
      .answered-detail:nth-child(even) { background-color: #f4f4f4; }      
    }
    .result-intro { max-width: 45ch; margin: 15px auto; text-align: center; font-size: 21px; }
    .result-container {
      & { display: block; margin: auto; padding: 0 30px 1px; max-width: 75%; }
      .result-title { font-size: 2rem; font-weight: 700; text-align: center; color: $color1; margin: 0.5rem auto 2rem auto; }
      .result-info { font-size: 1.25rem; text-align: center; max-width: 75%; margin: auto; margin-top: 2%; }
      .result-sections {
        .score-section { 
          & { display: flex; flex-direction: row; justify-content: center; align-items: center; }        
          .result-section-title { 
            & { width: 35%; text-align: left; font-weight: 600; }
            p { margin: 0.5rem 0; color: #747474; }
            span { color: #343333; }
          }
          .score-progress-bar {
            width: 40%; height: 25px;
            .MuiLinearProgress-determinate { height: 25px; background-color: #E8E8E8; 
              .MuiLinearProgress-bar1Determinate { background-color: #678F9D; }
            }
          } 
        }
        .first-result-title {
          .result-section-title p { & { font-weight: 700; color: #678F9D !important; } //color: $color1 !important; }
          span { color: #678F9D !important; }
          // .result-section-title p { & { font-weight: 700; color: #60b454 !important; } 
        }
          .score-progress-bar .MuiLinearProgress-determinate .MuiLinearProgress-bar1Determinate { background-color: #678F9D; }
        }
      }
    }
    .cta-btn { & { margin: 1rem auto; text-align: center; color: #fff; padding: 0 0 45px; }
      a { width: 100%; max-width: 280px; margin: 0 auto; background: linear-gradient(270deg, #77A07C 0%, #678F9D 100%); background: #60b454; display: block; text-decoration: none; border-radius: 5px;  color: #fff; text-align: center; }
      a:hover { background: $color2; background: #678F9D; transition: 0.0s; color: #fff; }
      button { width: 100%; color: #fff; font-family: "lato", sans-serif; font-size: 21px; font-weight: bold; padding: 7px 15px; }
    }
    .pdf-btn { 
      #pdf-convert-btn { background: $color2; }
    }

  }

  .results {
    & { min-height: 600px; background: #fff; color: $color3; width: 95%; }
    .spacer { height: 20px; }

    .selection-form { 
      &  { width: 50%; display: flex; align-items: center; margin: 20px auto; flex-wrap: wrap; justify-content: center; text-align: center; font-size: 36px; font-weight: 400; }
      .form-detail { flex: 0 1 100%; margin-right: 4%;}
      .form-text { flex: 0 1 100%; }
      .form-details-text { 
        & { background-color: #fff; border: 0px solid #efefef; border-bottom: 4px solid $color1; padding: 3px 7px; max-width: 90%; }
        .MuiInput-root { font-size: 36px; font-family: "lato", sans-serif; }
      }
    }

    h1 { width: auto; text-align: center; margin: 0 auto; font-family: 'lato', sans-serif; font-size: 36px; color: #343333; padding: 32px 32px 0; max-width: 45ch;
      .user-name { text-transform: capitalize; }
    }
    
    .progress-bar { margin: 80px auto 100px; position: relative; max-width: 90%; }
    .single-group_assessment #group-sections .si_ass_result { padding: 7% 0px 3%; }
    span.msi_name { color: $color1; font-size: 18px; font-weight: 900; font-family: "Avenir", Sans-serif; }
    .pro_top_details span { display: block; line-height: 30px; min-width: 180px; }
    .pro_top_details{ position: absolute; bottom: 45px; left: 0; max-width: 130px; width:100%; -webkit-transition: all .5s ease-in-out; -moz-transition: all .5s ease-in-out; -o-transition: all .5s ease-in-out; transition: all .5s ease-in-out; }
    .in-ass-result .si_ass_progress span, .single-assesment .si_ass_progress span { position: absolute; font-size: 30px; bottom: 42px; left: 0; font-weight: bold; color: #4A4A4A; font-family: "Aleo", Sans-serif; }
    .si_ass_progress:before {  }
    .single-group_assessment #group-sections .si_ass_progress:before { height: 45px; }

    .si_ass_progress { position: relative; max-width: 700px; height: 26px; margin: 0 auto; border-radius: 12px; width: 100%; 
      background-image: linear-gradient(to right, #cc2c24, #ffca26 62%, #429653 100%); 
      // background-image: linear-gradient(to right, #cc2c24 69.5%, #E98526 70% 79.5%, #FFCA27 80% 94.5%, #4E9A51 95% 100%);
    }
    .si_ass_progress .progress-line { z-index: 2; width: 2px; height: 35px; content: ' '; background: $color3; left: 0; bottom: 0; position: absolute; -webkit-transition: all .5s ease-in-out; -moz-transition: all .5s ease-in-out; -o-transition: all .5s ease-in-out; transition: all .5s ease-in-out; }
    .si_ass_progress .progress-line .progress-text { position: absolute; top: -65px; left: -100px; width: 200px; font-size: 18px; color: $color3; height: 30px; text-align: center; }
    .si_ass_progress .msi_score { display: block; position: absolute; font-size: 30px; font-weight: bold; color: #4A4A4A; bottom: 35px; left: 0; margin-left: -20px; }
    
    
    // .si_ass_progress .msi_score::after { display: block; margin: 0 33% 1rem 33%; content: ""; height: 50px; background-color: black; width: 2px; color: black; }
    
    
    
    .pervalue h4 { position: relative; top: 105px; }
    .pervalue { max-width: 700px; margin: -68px auto; display: flex; clear: both; color: #4A4A4A; font-size: 16px; }
    // .pervalue h4.nimp1:after { content: '0%'; position: absolute; right: -19px; bottom: 35px; }
    // .pervalue h4.nimp:after { content: '45%'; position: absolute; right: -19px; bottom: 35px; }
    // .pervalue h4.mexpe:after { content: '70%'; position: absolute; right: -19px; bottom: 35px; }
    // .pervalue h4.eexpe:after { content: '100%'; position: absolute; right: -19px; bottom: 35px; }
    .pervalue h4.nimp1:after { content: "0%"; position: absolute; right: -19px; bottom: 35px; font-weight: 700; font-size: 16px; }
    .pervalue h4.nimp:after { content: "50%"; position: absolute; right: -19px; bottom: 35px; font-weight: 700; font-size: 16px;}
    .pervalue h4.mexpe:after { content: "75%"; position: absolute; right: -19px; bottom: 35px; font-weight: 700; font-size: 16px;}
    .pervalue h4.eexpe:after { content: "100%"; position: absolute; right: -19px; bottom: 35px; font-weight: 700; font-size: 16px;}
    .pervalue h4.eexpe1:after { content: ""; position: absolute; right: -19px; bottom: 35px; }
    .pervalue h4.eexpe2:after { content: ""; position: absolute; right: -19px; bottom: 35px; }
    // .pervalue-text h4.nimp:after { content: 'Not Aligned'; position: absolute; right: -19px; bottom: -75px; }
    // .pervalue-text h4.mexpe:after { content: 'Needs Better Alignment'; position: absolute; right: -19px; bottom: -75px; }
    // .pervalue-text h4.eexpe:after { content: 'Aligned'; position: absolute; right: -19px; bottom: -75px; }
    .pervalue-text { & { position: relative; display: block; display: flex; margin: 0 auto; width: 100%; max-width: 700px; bottom: -55px; }
      p { display: inline-block; min-width: 10ch; text-align: center; margin: 5px auto; padding: 5px; box-sizing: border-box; top: 15px; font-size: 18px; vertical-align: top; color: #575757; line-height: 17px; }
    }

    .si_pro_header { max-width: 700px; margin: 10px auto 0; display: flex; clear: both; }
    .si_pro_header h4 { position: relative; font-size: 16px;  font-weight: 400; text-align: center; color:  $color3; text-transform: capitalize; margin-top: 10px; }
    .si_pro_header h4.nimp:before { width: 2px; height: 28px; content: ' '; background: #fff; position: absolute; right: 0; bottom: -75px; }
    .si_pro_header h4.mexpe:before { width: 2px; height: 28px; content: ' '; background: #fff; bottom: -75px; position: absolute; right: -2px; }
    .si_pro_header h4.eexpe:before { width: 2px; height: 28px; content: ' '; background: #fff; bottom: -75px; position: absolute; right: -2px; }
    .si_pro_header h4.eexpe1:before { width: 2px; height: 28px; content: ' '; background: #fff; bottom: -75px; position: absolute; right: 0; }
    .si_pro_header h4 span { color: $color1; line-height: 35px; font-size: 16px; }
    
    .result-text { text-align: center; font-size: 24px; margin: 20px auto; max-width: 65ch; background-color: #FFFFFF; padding: 5px 30px; border-radius: 10px; color: #121212; }
    .result-text p { text-align: center; padding-top: 0; margin-block-start: 0; margin: 0 auto; }
    .result-sections { 
      & { margin: 50px 5%; display: flex; justify-content: center; flex-wrap: wrap; padding-bottom: 50px; }
    }
    .score-section-container {
      & { display: flex; flex-direction: row; justify-content: center; align-items: center; max-width: 800px; padding: 0rem 2rem 2rem 2rem; margin: 1rem auto; }
      .score-section { 
        & { flex: 0 1 25%; text-align: center; color: $color3; font-size: 18px; font-weight: 700; margin: 10px auto; }
        .score-txt { font-size: 21px; font-weight: 700; }
        .score-name { font-size: 18px; font-weight: 400; margin: 0.25rem auto; text-transform: uppercase; }
        .score-slider { width: 90%; margin: 0 auto; margin-bottom: 16px; }
        .MuiLinearProgress-root.custom-bar { background-color: #E9E9E9; height: 10px; border-radius: 5px; width: 100%; }
        .custom-bar .MuiLinearProgress-bar { background-color: $color1; }
      }
    }

    
    .selection-form .form-details-text .MuiInput-root .MuiSelect-select { padding-right: 0; }
    //.css-eglki6-MuiLinearProgress-root { background: #E9E9E9; height: 5px; }
    //.css-5xe99f-MuiLinearProgress-bar1 { background: $color1; height: 5px; }

    .members-answered {
      & { max-width: calc(880px - 10%); background: #FBFBFB; margin: 20px auto; padding: 0px 0% 0px; font-family: "lato", sans-serif; font-size: 18px; color: #1f2a44; text-align: left; border-radius: 8px; }
      .section {
        & { background-color: #004266; color: #fff; padding: 10px 17px; margin: 0; font-weight: 700; border-top-left-radius: 3px;  border-top-right-radius: 3px; display: flex; align-items: center; justify-content: space-between; cursor: pointer; }
        .section-toggle { display: block; cursor: pointer; height: 24px; }
      }
      .answered-detail { 
        & { font-weight: 400; font-size: 18px; color: $color3; padding: 10px 30px; width: calc(100% - 60px); display: flex; align-items: center; }        
        .answer { flex: 1 1 285px; margin-left: 10px; text-align: right; font-weight: 700; }
        p { margin: 0; }
        .true { color: #73B76F; }
        .neutral { color: #FFB545; }
        .false { color: #FF6645; }
      }
      .answered-detail:nth-child(even) { background-color: #f4f4f4; }      
    }
    .invite-your-team {
      & { padding: 15px 15px 30px; display: flex; align-items: center; justify-content: center; }
      a { text-decoration: none; font-family: $primaryfont; }
      button { 
        &{font-family: $primaryfont; padding: .5rem 2rem; background-color: $colorbg; color: #FFFFFF; text-transform: uppercase; font-weight: 600; font-size: 21px;}
        &:hover{background-color: #F7D54E; color: #343333;}
      }
    }
  }

  .pdf-btn { 
    & { width: auto; padding: 0 0 3rem 0; margin: 45px auto 0; border: none; text-align: center; display: none; }
    .download-icon { vertical-align: sub; }
    #pdf-convert-btn { letter-spacing: 1px; background-color: #147FC5; padding: 0.75rem 1.25rem; text-transform: uppercase; font-family: "lato", sans-serif; font-size: 20px; font-weight: 700; color: #ffffff; border-radius: 5px; border: none; cursor: pointer; }
    #pdf-convert-btn:hover { background-color: #F18132; transition: 0.3s; }
  }

  .invite .details-container { padding: 1rem; .form-hr { display: none; } }
  .invite {
    & { min-height: 380px; width: 90%; max-width: 1110px; padding-bottom: 30px; background: #fff; }
    h1 { width: 100%; text-align: center; margin: 0 auto; font-family: "lato", sans-serif; font-size: 36px; color: #414042; padding: 38px 0 0; }
    p { text-align: center; margin: 25px auto 35px; font-size: 21px; line-height: 1.4em; max-width: 50ch; }
    .form {
      & { position: relative; display: flex; justify-content: space-between; flex-wrap: wrap; margin: 10px auto; width: 45%; }
      .invite-number { position: absolute; left: -50px; top: 63px; font-weight: bold; color: #C2C2C2; }
      .form-detail {
        & { flex: 0 1 46%; display: block; margin-bottom: 5px; font-weight: 700; }
        p { padding: 0; margin-bottom: 5px; text-align: left; font-size: 16px; font-weight: 700; }
        .MuiInput-root { font-family: 'lato', sans-serif !important; font-size: 18px; font-weight: 400; padding: 8px 9px; border-radius: 3px; }
        .MuiInput-root.Mui-focused { border: 1px solid #202945; }
        .invite-field { font-family: 'lato', sans-serif !important; background-color: #F4F4F4; padding: 0; border-radius: 3px; }
        .invite-field::placeholder { font-family: 'lato', sans-serif !important; font-size: 18px !important; color: #9B9B9B; font-weight: 300; }
        .invite-field input:focus::placeholder { color: transparent; }
        .error-text { bottom: -20px; margin: 0 auto -20px; color: red; font-weight: 400; font-size: 13px; }
      }
      .delete-btn { 
        & { position: absolute; right: -50px; top: 64px; }
        .delete-icon { color: #D5D5D5; font-size: 24px; cursor: pointer; }
      }
    }
    // .form > .form-detail:first-child > p:first-child { color: $color2; }
    // .form div.form-detail p:first-child { background-color: red; }

    // .invite-another-person { & { margin: 0 auto; width: 55%; position: relative; height: 5px; }
    .invite-btn-container .invite-another { width: 100px; position: relative; top: -62px; right: -79%; margin-left: 10px; border-radius: 5px; text-align: center;
      //.add-person-btn { font-family: $primaryfont; width: 100%; font-size: 1.1rem; font-weight: 700; padding: 7px 0.75rem; color: #fff; }
      .add-person-btn { font-family: $primaryfont; width: 100%; font-size: 1.1rem; font-weight: 700; padding: 7px 0.75rem; color: black;background:#f7D54E; cursor: pointer; }
    }
    // }

    .invite-btn-container .cta-btn {
      // & { display: block; margin: 30px auto 50px; text-decoration: none; width: 270px; background: linear-gradient(180deg, #77A07C 0%, rgba(103, 143, 157, 0.97) 100%); color: #fff; font-family: "lato", serif; border-radius: 5px; font-size: 21px; padding: 8px 0; text-align: center; text-transform: uppercase; font-weight: bold; }
      & { display: block; margin: 30px auto 50px; text-decoration: none; width: 270px; min-width: 160px; background-color:#F7D54E; color:#000; font-family: "lato", serif; border-radius: 5px; font-size: 21px; padding: 8px 0; text-align: center; text-transform: uppercase; font-weight: bold; }
      &:hover { background-color: #202945; color: #fff; }
      .button-progress { display: flex; justify-content: center; align-items: center; margin: 0 auto; padding: 7px 15px; }
    }
  }

  .thank-you {
    & { min-height: 380px; max-width: 926px; width: 100%; padding-bottom: 30px; background: #fff; }
    .tick { text-align: center; padding: 80px 0 0; }
    .tick img { height: 60px; width: 60px; }
    h1 { text-align: center; margin: 0 auto; font-family: "lato", serif; font-size: 36px; color: #414042; padding: 30px 15px 15px; }
    p { width: 65%; margin: 0 auto; text-align: center; font-size: 21px; }
    .back-btn { 
      & { text-align: center; padding: 2rem; }
      a { border-bottom: 2px solid $color1; text-transform: uppercase; font-weight: bold; cursor: pointer; text-decoration: none; color: #202020; }
    }
  }

  .invite-container {
    .invite-container-spacer { padding: 15px 30px 30px; max-width: 95%; margin: 0 auto 15px; }
    & { width: 95%; max-width: 1100px; box-sizing: border-box; margin: 4rem auto 2rem; padding: 0.25rem; border-radius: 10px; font-family: "lato", sans-serif; font-size: 21px; line-height: 32px; color: #414042; text-align: center; background-color: #FFFFFF; }
    .schedule-call { & { background-color: #F4F4F4; border-radius: 15px; width: 90%; margin: 45px auto 90px; padding: 15px 15px 30px; }
      p { max-width: 62ch; font-size: 21px; line-height: 42px; margin: 30px auto; }
    }
    h3 { font-weight: 700; font-size: 36px; margin: 30px auto 15px; line-height: 1; }
    p { max-width: 40ch; margin: 45px auto 0; }
    .height30 { height: 30px; }
    .invite-reasons {
      & { display: flex; padding: 73px 0 30px; }
      .reason { 
        & { flex: 0 1 33%; font-size: 21px; margin: 1rem; }
        span { color: #343333; font-weight: 700; }
        img { height: 55px; width: 55px; background-color: #678F9D; padding: 0.5rem; border-radius: 10px; }
      }
    }
    .cta-btn { & { width: 100%; max-width: 280px; background: linear-gradient(270deg, #77A07C 0%, #678F9D 100%); background: #60b454; margin: 1rem auto; color: #fff; padding: 0; border-radius: 5px; }
    &:hover { background: $color2; background: #678F9D; transition: 0.0s; color: #fff; }
    a { display: block; text-decoration: none; color: #fff; text-align: center; }
    button { width: 100%; color: #fff; font-family: "lato", sans-serif; font-size: 21px; font-weight: bold; padding: 7px 15px; }
  }
  }

  .team-container {
    & { max-width: calc(880px - 10%); border-radius: 10px; margin: 50px auto; font-family: "lato", sans-serif; font-size: 24px; line-height: 36px; color: #fff; text-align: center; font-weight: 400; width: 95%; box-sizing: border-box; }
    h3 { line-height: 37px; font-size: 36px; }
    .cta-btn {
      & { width: 220px; background: #3F86C7; margin: 40px auto 0; color: #fff; padding: 5px 0; border-radius: 25px; }
      &:hover { background: #1ea74a; transition: 0.3s; color: #fff; }
      a { display: block; text-decoration: none; color: #fff; }
      button { color: #fff; font-family: "lato", sans-serif; font-size: 18px; font-weight: bold; }
    }
  }

  .individual-group {
    & { max-width: 400px; width: 90%; margin: 70px auto 45px; display: flex; }
    button { cursor: pointer; flex: 0 1 50%; border: 0px; background: #fff; color: #414042; font-family: "lato", sans-serif; font-size: 18px; font-weight: 400; padding: 16px 0; text-align: center; }
    .left { border-top-left-radius: 5px; border-bottom-left-radius: 5px; }
    .right { border-top-right-radius: 5px; border-bottom-right-radius: 5px; }
    .active { background:#F7D54E; color: #343333; font-weight: 600; }
  }

  .invite-legacy-container {
    & { display: flex; justify-content: center; align-items: center; max-width: 970px; margin: 0 auto; }
    .invite-legacy { & { background-color: #FBF9F2; margin: 15px 25px; padding: 30px 2% 30px; font-family: "lato", sans-serif; font-size: 24px; font-weight: 700; line-height: 36px; color: $color3; text-align: center; border-radius: 10px; width: 100%; }
      h1 { width: auto; text-align: center; margin: 0 auto; margin-bottom: 2rem; font-family: 'lato', sans-serif; font-size: 36px; color: $color3; padding: 32px 32px 0; max-width: 45ch;
        .user-name { text-transform: capitalize; }
      }
      p { margin: 0 auto; max-width: 49ch; padding: 0 30px; font-weight: 400; }
      .cta-btn {
        & { width: 100%; max-width: 280px; background: $color1; margin: 45px auto 30px; color: #343333; border-radius: 3px; }
        // &:hover { background: #202945; color: #343333; transition: 0.3s; color: #fff; }
        a { display: block; text-decoration: none; color: #343333; }
        button {
          & { width: 100%; color: #343333; padding: 7px 5px; font-family: "lato", sans-serif; font-size: 21px; border-radius: 3px; font-weight: 700; }
          &:hover{background:#202945 ;color: #fff; transition: 0.3s;}
        }
      }
    }
  }

  .toggle-individual-group { display: none; }
  .dashboard {
    & { min-height: 630px; background: #fff; padding: 1rem 1rem 3rem; }
    h1 { width: auto; text-align: center; margin: 0 auto; font-family: 'lato', serif; font-size: 36px; color: #333333; padding: 30px 15px 0; }

    .selection-form { 
      &  { width: 50%; display: flex; align-items: center; margin: 20px auto; flex-wrap: wrap; justify-content: center; text-align: center; font-size: 36px; font-weight: 400; }
      .form-detail { & { flex: 0 1 100%; margin-right: 4%; }
      }
      .form-text { flex: 0 1 100%; }
      .form-details-text { 
        & { background-color: #fff; border: 0px solid #efefef; border-bottom: 4px solid $color1; padding: 3px 7px; max-width: 90%; }
        .MuiInput-root { font-size: 36px; font-family: "lato", sans-serif; }
      }
    }

    .result-sections {
      & { margin: 45px 3% 45px; display: flex; justify-content: center; flex-wrap: wrap; padding-bottom: 50px; }
      .individual-scores-container { margin: 0 auto 60px; }
  }

  .members-answered {
    & { max-width: calc(880px - 10%); background: #FBFBFB; margin: 20px auto; padding: 0px 0% 0px; font-family: "lato", sans-serif; font-size: 18px; color: #1f2a44; text-align: left; border-radius: 8px; }

    .section {
      & {text-transform: uppercase; background-color: #202945; color: #fff; padding: 10px 17px; margin: 0; font-weight: 700; border-top-left-radius: 3px; border-top-right-radius: 3px; display: flex; align-items: center; justify-content: space-between; cursor: pointer;  }
      .section-toggle { display: block; cursor: pointer; height: 24px; }
  }
  .answered-detail { 
    & { font-weight: 400; font-size: 18px; color: $color3; padding: 10px 30px; width: calc(100% - 60px); display: flex; align-items: center; }        
    .answer { flex: 1 1 285px; margin-left: 10px; text-align: right; font-weight: 700; }
    p { margin: 0; }
    .true { color: #73B76F; }
    .neutral { color: #FFB545; }
    .false { color: #FF6645; }
  }
  .answered-detail:nth-child(even) { background-color: #f4f4f4; } 
}


    .progress-bar { margin: 65px auto 15px; }
    .single-group_assessment #group-sections .si_ass_result { padding: 7% 0px 3%; }
    span.msi_name { color: #d3202b; font-size: 18px; font-weight: 900; font-family: "lato", sans-serif; }
    .pro_top_details span { display: block; line-height: 30px; min-width: 180px; }
    .pro_top_details{ position: absolute; bottom: 45px; left: 0; max-width: 130px; width:100%; -webkit-transition: all .5s ease-in-out; -moz-transition: all .5s ease-in-out; -o-transition: all .5s ease-in-out; transition: all .5s ease-in-out; }
    .in-ass-result .si_ass_progress span, .single-assesment .si_ass_progress span { position: absolute; font-size: 30px; bottom: 42px; left: 0; font-weight: bold; color: #4A4A4A; font-family: "Aleo", Sans-serif; }
    .si_ass_progress:before {  }
    .single-group_assessment #group-sections .si_ass_progress:before { height: 45px; }

    .si_ass_progress { position: relative; max-width: 700px; height: 26px; margin: 0 auto; border-radius: 13px; width: 100%; background-image: linear-gradient(to right, #cc2c24, #ffca26 62%, #429653 100%); }
    .si_ass_progress .progress-line { z-index: 9; border: 1px solid #4A4A4A; width: 1px; height: 30px; content: ' '; background: #4a4a4a; left: 0; bottom: 0; position: absolute; -webkit-transition: all .5s ease-in-out; -moz-transition: all .5s ease-in-out; -o-transition: all .5s ease-in-out; transition: all .5s ease-in-out; }
    .si_ass_progress .msi_score { display: block; position: absolute; font-size: 30px; font-weight: bold; color: #22223B;; bottom: 35px; left: 0; margin-left: -20px; }
    
    .pervalue h4 { position: relative; }
    .pervalue { max-width: 700px; margin: -16px auto; display: flex; clear: both; color: #333333; font-size: 14px; }
    // .pervalue h4.nimp:before { content: '0%'; position: absolute; left: 0; }
    // .pervalue h4.nimp:after { content: '45%'; position: absolute; right: -19px; }
    // .pervalue h4.mexpe:after { content: '70%'; position: absolute; right: -19px; }
    // .pervalue h4.eexpe:after { content: '100%'; position: absolute; right: 0; }
    .pervalue h4.nimp:before { content: '0%'; position: absolute; left: 0; bottom: -19px; font-weight: 700; font-size: 16px;}
    // .pervalue h4.nimp1:after { content: "0%"; position: absolute; right: -19px; font-weight: 700; font-size: 24px; }
    .pervalue h4.nimp:after { content: "50%"; position: absolute; right: -19px; bottom: -19px; font-weight: 700; font-size: 16px;}
    .pervalue h4.mexpe:after { content: "75%"; position: absolute; right: -19px; bottom: -19px; font-weight: 700; font-size: 16px;}
    .pervalue h4.eexpe:after { content: "100%"; position: absolute; right: -2px; bottom: -19px; font-weight: 700; font-size: 16px;}

    .si_pro_header { max-width: 700px; margin: 0 auto; display: flex; clear: both; }
    .si_pro_header h4 { position: relative; font-size: 18px;  font-weight: 400; text-align: center; color: #333333; text-transform: capitalize; margin-top: 10px; line-height: 18px; }
    .si_pro_header h4.nimp:before { width: 2px; height: 28px; content: ' '; background: #fff; position: absolute; right: 0; bottom: 50px; }
    .si_pro_header h4.mexpe:before { width: 2px; height: 28px; content: ' '; background: #fff; bottom: 50px; position: absolute; right: 0; }
    .si_pro_header h4 span { color: #D3202B; line-height: 35px; font-size: 16px; }
    
    .results .result-container { padding-bottom: 0; }
    .result-text { & { text-align: center; font-size: 21px; font-weight: 400; color: #000; ; padding: 5px 30px; margin: 15px auto 15px; box-sizing: border-box; width: 90%; max-width: 860px; }
      p { margin: 15px auto; }
    }
    
    .score-section-container {
      & { display: flex; flex-direction: row; justify-content: center; align-items: center; max-width: 800px; padding: 0rem 2rem 1rem 2rem; margin: 1rem auto; }
      .score-section { 
        & { flex: 0 1 25%; text-align: center; color: $color3; font-size: 18px; font-weight: 700; margin: 10px auto; }
        .score-txt { font-size: 21px; font-weight: 700; }
        .score-name { font-size: 21px; font-weight: 400; margin: 0.25rem auto; text-transform: uppercase; color: #000;}
        .score-slider { width: 90%; margin: 0 auto; margin-bottom: 16px; }
        .MuiLinearProgress-root.custom-bar { background-color: #E9E9E9; height: 10px; border-radius: 5px; width: 100%; }
        .custom-bar .MuiLinearProgress-bar { background-color: $color1; }
      }
    }




    .pdf-result-sections { display: none; }
    .thumbnail { position: absolute; top: -15%; width: 100%; height: auto; overflow: hidden; }
    .bar-container {
      & { display: block; margin: auto; padding: 0 15px 30px; }
      .result-title { font-size: 2rem; font-weight: 700; text-align: center; color: $color1; margin: 0.5rem auto 2rem auto; padding-top: 0; }
      .result-info { font-size: 1.25rem; text-align: center; max-width: 75%; margin: 15px auto; }
      .result-sections {
        .score-section { 
          & { display: flex; flex-direction: row; justify-content: center; align-items: center; }        
          .result-section-title { 
            & { width: 22ch; text-align: left; font-weight: 600; }
            p { margin: 0.5rem 0; color: #747474; }
            span { color: #343333; font-family: "lato", sans-serif !important; }
          }
          
          .score-progress-bar {
            & { width: 40%; height: 25px; position: relative; }
            .MuiLinearProgress-determinate { height: 25px; background-color: #E8E8E8; 
              .MuiLinearProgress-bar1Determinate { background-color: #678F9D; }
            }
          } 
          .user-amount {
            & { text-align: left; min-width: 70px; display: flex; align-items: center; margin-left: 0.5rem; }
            img { margin: 0 5px; }
            .number { margin: 0 5px; min-width: 25px; font-size: 14px; font-weight: 700; color: #447FA6; }
          }
        }
        .first-result-title {
          .result-section-title p { & { color: #678F9D; font-weight: 700; }
             span { color: #678F9D !important; }
          }
          // .score-progress-bar .MuiLinearProgress-determinate .MuiLinearProgress-bar1Determinate { background-color: #678F9D; }
          .score-progress-bar .MuiLinearProgress-determinate .MuiLinearProgress-bar1Determinate { background-color: #678F9D; }
        }
      }
    }


    .details-container { padding: 0 7% 1rem; padding: 0 0 15px; width: 95%; max-width: 800px; margin: 0 auto; }

    .MuiLinearProgress-root.custom-bar { background-color: #E9E9E9; height: 10px; border-radius: 5px; }
    .custom-bar .MuiLinearProgress-bar { background-color: #1EA74A; }

    table { 
      & { font-family: 'lato', sans-serif; border-collapse: collapse; width: 86%; max-width: 800px; margin: 60px auto 40px; font-size: 18px; font-weight: 400; }
      tr:nth-child(even) { background-color: #F4F4F4; }
      td, th { border: 1px #dddddd;text-align: left;padding: 8px; }
      td:first-child { font-weight: 700; color: #2C2C2C; }
      td:nth-child(2) { color: #2C2C2C; }
      td:last-child { font-weight: 700; }
      .center-cell { text-align: center; text-transform: capitalize; }
      .medium { font-weight: 500; }
      .hash { width: 13px; }
      .avg { text-align: center; }
      .header { border-style: none; border-bottom-style: solid; border-width: 3px; border-color: $color1; }
      .footer { 
        & { border-style: none; border-top-style: solid; border-width: 3px; border-color: $color1; color: $color1; }
        .church-average { font-size: 16px; color: #414042; }
      }
    }

    hr { width: 96%; color: #979797; }
    .answers-header { display: none; }
    .answers-container { display: flex; }
    .most-answered {
      & { margin: 15px auto; width: 95%; max-width: 800px; padding: 0; font-family: "lato", sans-serif; font-size: 18px; color: #414042; text-align: left; }
      .green { background-color: #73B76F; color: #fff; padding: 10px 17px; margin: 0; font-weight: 700;  border-radius: 10px 10px 0 0; }
      .red { background-color: #FF6645; color: #fff; padding: 10px 17px; margin: 0; font-weight: 700;  border-radius: 10px 10px 0 0; display: flex; justify-content: space-between; }
      .most-answered-detail { font-weight: 400; font-size: 18px; color: #4A4A4A; background-color: #FBFBFB; padding: 10px 30px; width: calc(100% - 60px);}
      .most-answered-detail:nth-child(even) { background-color: #f4f4f4; }
      .most-answered-detail:last-child { border-radius: 0 0 10px 10px; }
    }

    h2.dashboard-video-header { font-family: "lato", serif; font-size: 36px; font-weight: 700; line-height: 43px; text-align: center; margin: 50px auto 0 auto; }
    .dashboard-video-title { font-size: 24px; color: #868686; line-height: 29px; text-align: center; margin: 1rem auto 2rem auto; }

    .dashboard-video {
      // & { margin-top: 3rem;}
      .video { & {width: 100%; margin: auto; border-radius: 0; height: auto; overflow: hidden; }
        video { width: 100%; height: 620px; padding: 1rem; }
       }
    }
    .dashboard-video-subtext { font-size: 24px; font-weight: 700; max-width: 50ch; text-align: center; margin: 4rem auto; }
    
    .cta-btn-learn {
      & { width: max-content; text-align: center; background: $color1; margin: 0 auto; color: #fff; padding: 0; border-radius: 5px; transition: 0.3s; 
        // margin: 0 auto 5rem auto; 
      }
      &:hover { background: $color2; color: #fff; }
      a { display: block; text-decoration: none; color: #fff; }
      button { color: #fff; font-family: "lato", sans-serif; font-size: 21px; font-weight: bold; padding: 0.5rem 2rem; }
    }

    .alignment { 
      & { margin: 0 auto 30px; margin: 45px auto 0; padding: 0; border-radius: 10px; }
      .container-tabs { display: flex; flex-direction: column; position: relative; width: 100%; min-height: 300px; }
      .tab-buttons { 
        & { display: flex; width: 100%; justify-content: space-evenly; margin: 0 auto; background-color: #F7D54E; border-radius: 10px; overflow: hidden; padding: 6px 15px 0; box-sizing: border-box; }
        button { font-family: "lato", sans-serif; color: #202945; font-size: 20px; border-radius: 3px; font-weight: 400; text-transform: uppercase; text-align: center; padding: 15px 15px 20px; background: none; border: 0px; min-width: 110px; cursor: pointer; width: 100%; max-width: 200px; }
        .active { background-color: #FBF9F2; color: #202945; border-radius: 5px 5px 0 0; padding: 15px; font-weight: 700;}
      }
      .tabs-content { flex-grow: 1; background-color: #FBF9F2; border-radius: 10px; }

      .content { 
        & { width: 100%; display: none; }
        .icon-container-box { display: flex; justify-content: center; align-items: center; }
        .align-details {
          & { max-width: 96%; background: #FBFBFB; margin: 20px auto; padding: 0px 0% 0px; font-family: "lato", sans-serif; font-size: 18px; color: #414042; text-align: left; border-radius: 10px; overflow: hidden; }
          .green { background-color: #73B76F; color: #fff; margin: 0; font-weight: 700; border-top-left-radius: 3px;  border-top-right-radius: 3px; }
          .red { background-color: #FF6645; color: #fff; margin: 0; font-weight: 700; border-top-left-radius: 3px;  border-top-right-radius: 3px; border-radius: 10px 10px 0 0; overflow: hidden; }
          .MuiAccordionSummary-root .MuiAccordionSummary-content.Mui-expanded { margin: 0; }
          .MuiAccordionSummary-root .MuiAccordionSummary-content { justify-content: space-between; }
          .answered-detail { 
            & { display: flex; flex-wrap: wrap; align-items: center; font-weight: 300; font-size: 16px; color: #414042; padding: 10px 15px; width: calc(100% - 30px); cursor: pointer; }
            .answer { flex: 1 1 200px; margin-left: 10px; text-align: right; font-weight: 700; }
            .true { color: #73B76F; }
            .neutral { color: #FFB545; }
            .false { color: #FF6645; }
            p { flex: 0 1 94%; }
            .more-toggle { display: block; flex: 0 1 6%; cursor: pointer; }
            .more-details { 
              & { display: none; width: 91%; margin-left: 5%; padding: 5px 2%; border-left: 3px solid #E66767; font-size: 14px; }
              .more-row { 
                & { display: flex; align-items: center; }
                p { max-width: 30%; padding: 5px; margin: 0; }
                .more-answer { text-transform: uppercase; font-weight: bold; }
              }
            }
          }
          .no-wrap { flex-wrap: nowrap; }
          .answered-detail:nth-child(even) { background-color: #f4f4f4; }
        }
      }
      .active-content { display: block; }
    }

    .mobile_scores {
      & { display: none; width: 90%; margin: 0 auto; }
      .individual {
        h2 { font-size: 18px; font-weight: 700; }
        & { font-size: 21px; font-weight: 700;border-bottom: 2px solid #202945;  }
        .row { 
          & { cursor: pointer; height: 40px; display: flex; align-items: center; border-top: 2px solid #202945; color: #414042; }
          &:last-of-type { border-bottom: 2px solid #202945; }
          .number { flex: 0 1 30px; font-size: 18px; }
          .name { flex: 1 1 auto; }
          .percent { color: $color1; font-size: 18px; margin-right: 10px; }
          .toggle { padding-top: 6px; }
        }
        .scores { 
          & { font-size: 18px; border-top: 2px solid #202945; }
          .section_rows { 
            & { display: flex; padding: 12px 16px; }
            &:nth-of-type(even) { background-color: #F4F4F4; }
            .section { flex: 1 1 auto; }
            .section_score { flex: 0 1 auto; font-weight: 400; }
          }
        }
      }
    }

    .urgency {
      & { display: none; // margin: 20px auto; 
      }
      h2 { font-family: "lato", sans-serif; font-weight: 700; text-transform: uppercase; font-size: 1.25rem; text-align: center; }
      .urgency-slider { 
        & { position: relative; background: $color1opacity30; margin: 0 auto; padding: 115px 9% 80px; border-radius: 3px; }
        h3 { position: absolute; top: 0; color: #152036; font-weight: 300; font-size: 15px; left: 7%; }

        .slider-labels {
          & { 
            // width: 125%; 
            width: 100%;
            font-weight: 400; font-size: 13px; color: #000; margin: 0 auto; }
          .label {
            & { width: 20%; float: left; position: relative; }
            p { display: block; position: absolute; margin-left: -50%; margin-top: -20px; padding-top: 40px; width: 100%; text-align: center; z-index: 1000; }
          }
        }
      
      }

      .slider-points {
        & { 
          // width: 125%; 
          width: 100%;
          margin-top: -20px; font-weight: 300; font-size: 14px; color: #343333; position: relative; }
        .score { position: absolute; z-index: 100000; margin-left: -8px; margin-left: -13px; margin-top: -12px; text-align: center; font-size: 18px; font-weight: bold; color: #fff; background-color: #4C88AE; border-radius: 25%; }
        .top-labels { position: absolute; width: 1px; height: 33px; top: -46px; margin-left: -2px; background: #1F2A44; }
        .top-points { position: absolute; top: -70px; margin-left: -6px; font-weight: bold; font-size: 18px; color: #152036; }
        .point {
          & { cursor: pointer; width: 13px; height: 13px; border-radius: 8px; margin-left: -8px; margin-top: -6px; background: #4C88AE; position: absolute; z-index: 9; }
        }
      }

      // .custom-slider.MuiSlider-root { z-index: 1000; color: $color1opacity42; width: calc(100% + 16px); margin-left: -8px; }
      .custom-slider.MuiSlider-root { 
        & { z-index: 1000; color: $color1opacity42; width: 100%; margin-left: 0; }
        span.MuiSlider-track { left: -1% !important; }
      }

      .custom-slider .MuiSlider-rail { opacity: 0; }
      .custom-slider .MuiSlider-track { border: 8px solid $color1opacity60; height: 0; }

      .custom-slider .MuiSlider-thumb { background-color: #4C88AE; }
      .custom-slider .MuiSlider-thumb:before { background: #4C88AE; border: 4px solid #4C88AE; width: 36px; height: 36px; border-radius: 50%; box-shadow: 0px 0px 0px 0px rgba(247, 213, 77, 0.5); }
      .custom-slider .MuiSlider-thumb.Mui-focusVisible { box-shadow: 0px 0px 0px 8px rgba(247, 213, 77, 0.5); }
      .custom-slider .MuiSlider-thumb:hover { box-shadow: 0px 0px 0px 8px rgba(247, 213, 77, 0.5); }
      
      .MuiLinearProgress-root.custom-bar { background-color: #152036; height: 2px; }
      .custom-bar .MuiLinearProgress-bar { background-color: $color1; }
    }

    .new-slider-header { display: none; }
    .new-slider-container { display: none; }

    .hurdles-enablers {
      // & { border-radius: 13px; }
      // & { display: none; }
      h2 { font-family: "lato", sans-serif; font-weight: 700; text-transform: uppercase; font-size: 18px; text-align: left; }
      .hurdles { background: #fbece9;  padding: 10px 4% 1px 2%; font-family: "lato", sans-serif; font-size: 16px; line-height: 1.5em; color: #313131; text-align: left; border-radius: 13px; }
      .enablers { background: #f1f8f1;  padding: 10px 4% 1px 2%; font-family: "lato", sans-serif; font-size: 16px; line-height: 1.5em; color: #313131; text-align: left; border-radius: 13px; }
      li { padding-bottom: 16px; font-weight: 400; font-size: 14px; }
    }
  }

  .results, .dashboard {
    // .result-text { padding-top: 2rem; }
    .result-text p:last-child { margin: 10px auto 15px; }
    .tab-change { & { text-align: center; font-size: 18px; font-weight: 700; margin: 15px auto 30px; width: 100%; }
        p {  }
        .tab-btn-container { & { display: flex; justify-content: space-between; align-items: center; max-width: 400px; margin: 0 auto; }
          .section-name { text-transform: uppercase; padding: 0 15px; }
          button { border-radius: 4px; outline: none; border: none; cursor: pointer; }
        }
      }
    .result-sections { 
      // & { margin: 50px 5%; display: flex; justify-content: center; flex-wrap: wrap; }
      & { margin: 45px 3% 45px; justify-content: center; }
      .individual-scores-container { margin: 0 auto 60px; }
      .score-section { 
        & { flex: 0 1 25%; text-align: center; color: #333333; font-weight: 400; font-size: 18px; margin: 10px auto; }
        .score-txt { font-size: 18px; font-weight: 700; margin-bottom: 28px; }
        .score-slider { width: 80%; margin: 0 auto; margin-bottom: 16px; }
      }
    }
    .result-sections {
      .score-section {
        & { display: flex; flex-direction: row; justify-content: center; align-items: center; }        
        .result-section-title { 
          & { width: 24ch; text-align: left; font-weight: 600; }
          p { margin: 0.5rem 0; color: #747474; }
          span { color: #343333; font-family: "lato", sans-serif !important; }
        }
        
        .score-progress-bar {
          & { width: 40%; height: 25px; position: relative; }
          .MuiLinearProgress-determinate { height: 25px; background-color: #E8E8E8; 
            .MuiLinearProgress-bar1Determinate { background-color: #678F9D; }
          }
        } 
        .user-amount {
          & { text-align: left; min-width: 70px; display: flex; align-items: center; margin-left: 0.5rem; }
          img { margin: 0 5px; }
          .number { margin: 0 5px; min-width: 25px; font-size: 14px; font-weight: 700; color: #447FA6; }
        }
      }
      .first-result-title {
        .result-section-title p { & { color: #678F9D; font-weight: 700; } // color: $color1 !important; }
           span { color: #678F9D !important; }
        }
        .score-progress-bar .MuiLinearProgress-determinate .MuiLinearProgress-bar1Determinate { background-color: #678F9D; }
      }
      .selected-member-data { & { background-color: #DCDBDB; border-radius: 5px; padding: 15px 15px 30px; }
        .even { background-color: #FBFBFB; }
        .odd { background-color: #F4F4F4; }
        .question-answer { & { display: flex; justify-content: space-between; align-items: center; max-width: 90%; margin: 0 auto; padding: 5px 20px; font-size: 15px; }
          .question { max-width: 80ch; }
          .answer-text { font-weight: 700; width: 20ch; text-transform: uppercase; text-align: right; }
          .red { color: #F17E7E; }
          .yellow { color: #EBBE36; }
          .green { color: #6CAFB5; }
        }
      }
    }
    .hurdles-enablers {
      // & { border-radius: 13px; } // & { display: none; }
      h2 { font-family: "lato", sans-serif; font-weight: 700; text-transform: uppercase; font-size: 18px; text-align: left; }
      .hurdles { background: #fbece9;  padding: 10px 4% 1px 2%; font-family: "lato", sans-serif; font-size: 16px; line-height: 1.5em; color: #4A4A4A; text-align: left; border-radius: 13px; }
      .enablers { background: #f1f8f1;  padding: 10px 4% 1px 2%; font-family: "lato", sans-serif; font-size: 16px; line-height: 1.5em; color: #4A4A4A; text-align: left; border-radius: 13px; }
      li { padding-bottom: 16px; font-weight: 400; font-size: 18px; }
    }
  }

  // .testimonial-container {
  //   & { max-width: calc(880px - 4%); margin: 50px auto 0; padding: 0 0 3rem 0; font-family: 'lato', sans-serif; font-size: 24px; line-height: 36px; color: #414042; display: flex; }
  //   .testimonial { 
  //     & { position: relative; color: #27282A; font-family: 'lato', sans-serif; font-size: 24px; line-height: 45px; text-align: center; }
  //     .cta-btn { width: 350px; }
  //     .avatar {
  //       img { border-radius: 50%; width: 120px; margin-top: 50px; margin-bottom: -20px; }
  //     }
  //     .quotation-mark { 
  //       & { display: inline-block; font-size: 84px; color: #3884C6; margin-right: 15px; position: relative; }
  //       .quotation-block { position: absolute; right: 15px; top: -15px; }
  //     }
  //     .bottom { transform: scale(-1, -1); margin-left: 15px; }
  //     .designation { 
  //       & { color: #424242; font-weight: 700; padding-top: 32px; line-height: 1.1em; font-size: 32px; }
  //       span { font-weight: 600; font-size: 18px; color: #343333; }
  //     }
  //   }
  // }
  .testimonial-container {
    & { max-width: calc(880px - 4%); width: 85%; margin: 50px auto 0; padding: 75px 30px 30px; font-family: 'lato', sans-serif; font-size: 24px; line-height: 36px; color: $color3; display: flex; justify-content: center; }
    .testimonial { 
      & { position: relative; color: #ffffff; font-family: 'lato', sans-serif; font-size: 24px; line-height: 45px; text-align: center; }
      .testi { max-width: 55ch; }
      .avatar {
        img { border-radius: 50%; width: 120px; margin-top: 50px; margin-bottom: -20px; }
      }
      .quotation-mark { 
        & { display: block; text-align: left; font-size: 84px; color: $color1; margin-right: 15px; position: relative; line-height: 0; left: -39px; left: -5%; } // top: 13.5px;
        // .quotation-block { position: absolute; right: 15px; top: -15px; }
      }
      .quotation-mark.bottom { transform: scale(-1, -1); margin-left: 15px; text-align: left; top: -10px; left: -40px; left: 5%; }
      .testimonial-text { padding: 2px 0; }
      .designation { 
        & { color: $color1; font-weight: 700; padding-top: 32px; line-height: 1.1em; font-size: 32px; }
        span { font-weight: 600; font-size: 18px; color: #ffffff; }
      }
    }
  }

  .testimonial-invite {
    & { font-family: "Lato", sans-serif; background-color: $colorbg; margin: 2rem; margin-top: 0; padding: 3rem; color: #fff; border-radius: 10px; font-size: 1.5rem; }
    p { max-width: 46ch; margin: 1rem auto 2rem auto; }
    .cta-btn { margin: 3rem auto 2rem auto; }
  }
  .source-section { & { box-sizing: border-box; background-color: #D9F0FF; padding: 2rem; max-width: 1110px; margin: 30px auto 60px; border-radius: 10px; }
    .source-info {
      & { background-color: #00558C; margin: 1rem; padding: 1rem; color: #fff; border-radius: 10px; }
      p { font-family: "Lato", sans-serif; max-width: 46ch; margin: 60px auto 45px auto; padding: 0 15px; font-size: 24px; font-weight: 700; text-align: center; }
      .cta-btn { & { margin: 0 auto 30px auto; text-align: center; }
        a { text-decoration: none; color: #ffffff; }
        button { background-color: #F18132; padding: 12px 45px; font-size: 21px; color: #ffffff; font-weight: 700; font-family: "Lato"; transition: all 0.25s; }
        button:hover { background-color: #348CFE; }
      }
    }
  }

  .schedule-container {
    & { max-width: calc(880px - 10%); background-color: $colorbg; margin: 20px auto; padding: 15px 5% 30px; font-family: "lato", sans-serif; font-size: 24px; line-height: 36px; color: #fff; text-align: center; border-radius: 10px; }
    h3 { margin: 0 auto; padding: 5px 15px; font-size: 36px; }
    p { margin: 30px auto; font-size: 21px; line-height: 32px; max-width: 48ch; }
    .call-link {
      button { & { max-width: 220px; width: 100%; color: $colorbg; background-color: $color1; font-family: $primaryfont; font-size: 18px; border-radius: 5px; font-weight: bold; border: 1px solid transparent; padding: 12px 30px; margin: 15px auto; transition: all 0s; }
        a { display: block; text-decoration: none; color: $colorbg; }
        a:hover {  }
      }
      button:hover { & { background-color: #FFF4C6; border: 1px solid #D8D8D8; }
        a {  }
      }
    }
  }

  // .invite-legacy-container {
  //   & { max-width: calc(880px - 10%); background: #00558C; margin: 50px auto; padding: 75px 5% 30px; font-family: "lato", sans-serif; font-size: 24px; line-height: 36px; color: #fff; text-align: center; border-radius: 10px; }
  //   .cta-btn {
  //     & { width: 280px; background: $color1; font-size: 21px; font-weight: bold; margin: 1.5rem auto 1rem auto; color: #fff; padding: 5px 0; border-radius: 5px; }
  //     &:hover { background: #E3A352; color: #343333; transition: 0.3s; color: #fff; }
  //     a { display: block; text-decoration: none; color: #fff; }
  //     button { color: #fff; font-family: "lato", sans-serif; font-size: 21px; border-radius: 3px; font-weight: bold; }
  //   }
  // }

}

.btn { & { }}

footer { padding: 1rem 1rem 2rem 1rem; // min-height: 100px;
  .footer-logos-div {
   // & { display: flex; justify-content: space-between; align-items: center; max-width: 1110px; margin: auto; }
   & { display: flex; justify-content: center; align-items: center; max-width: 1110px; margin: auto; }
    .footer-logo-left img { width: 180px; max-height: 64px; }
    .footer-logo-right img { width: 50px; max-height: 64px; }
    .footer-logo-center img { width: 106px; max-height: 44px; }
    // .footer-logo-left img { width: 50px; max-height: 64px; }
  }

}

@media only screen and (min-width: 650px) {

  main .assessment-additional .steps .slider-container .slider-labels br { content: ""; }
  main .assessment-additional .steps .slider-container .slider-labels br:after { content: " "; }

}



@media only screen and (max-width: 1350px) {

  header {
    .logo { margin-left: -50px; }
  }  

}

@media only screen and (max-width: 1250px) {

  header {
    .logo { margin-left: 0; }
    .assessment-title p { max-width: 28ch; }
  }
  .custom-slider.MuiSlider-root { padding: 12px 0; }  

}

@media only screen and (max-width: 1110px) {

  main .dashboard .urgency { overflow: hidden; }
  main .results .result-container .result-sections .score-section .result-section-title { width: 45%; }
  main .dashboard .alignment .tab-buttons button { font-size: 18px; }
  main .start .intro h1 { font-size: 32px; line-height: 42px; }

}

@media only screen and (max-width: 830px) {

  header {
    // & { height: auto; align-items: flex-start; margin: 0px auto; }
    & { height: auto; margin: 0px auto; display: grid; grid-template-columns: 0.25fr 1fr 0.25fr; }
    .logo { position: relative; margin: 0 auto; left: 0; margin-bottom: 15px; margin-top: 10px; }
    .assessment-title { & { font-size: 28px; box-sizing: border-box; line-height: 32px; }
      img { height: 40px; width: 40px; }
      p { font-size: 21px; line-height: 27px; }
      .leadership { width: 164px; }
      .mob_lead { width: auto; height: 64px; margin: 0 8%; }
    }
    .logo-right { display: none; }
  }

  // footer { min-height: 0; height: 0; }
  
  main {
    .start { & { flex-direction: column; height: auto; min-height: unset; }
      .video { & { height: 370px; min-height: 80vw; width: 100%; border-radius: 10px 10px 0 0; }
        .playbtn { top: 90%; width: 180px; }
      }
      .intro { & { width: 100%; padding: 30px; box-sizing: border-box; height: auto; text-align: center; border-radius: 0 0 10px 10px; }
        h1 { margin: 0 auto; max-width: 30ch; font-size: 28px; }
        h2 { margin: auto; }
        .intro-points { & { margin: 30px auto 15px; }
          p { font-size: 21px; text-align: left; }
        }
        .logos-section { & { margin: 5px auto 30px; }
          .logo-left { width: 120px; height: auto; }
          .logo-right { width: 120px; height: auto; }
        }
        .completion {
          flex-direction: column; margin-top: 2rem;
          .time-estimate { margin: 1rem auto; padding: 0; font-weight: 700; margin-top: 8px;}
        }
      }
    }

    .start .video .owner-details { height: 60px; }

    .start .video .owner-details .name-mobile { margin: 0; display: block; }
    .start .video .owner-details .position-mobile { font-weight: 600; display: inline-flex; }
    .start .video .owner-details .company-mobile { margin-left: 1ch; font-weight: 600; display: block; }
  
    .start .video .owner-details .name { display: none; }
    .start .video .owner-details .position { display: none; }
    .start .video .owner-details .company { display: none; }

    .how-it-works {
      flex-direction: column; height: auto;
      .info-text-desktop { display: none; }
      .info-text-mobile { & { display: block; font-size: 13px; font-weight: 500; padding: 0 0.5rem 0 0; margin: 20px 0.25rem 0; position: relative; bottom: 0; align-self: center; text-align: center; font-style: italic; }
        .more-info { display: inline-block; color: $color2; }
      }
      .how-it-works-tiles {
        margin: 1rem auto 1rem auto;
      }
      .how-it-works-explanation {
        grid-template-rows: auto auto;
        width: 100%;
        text-align: center;
        max-width: 55ch;
        margin: 1rem auto;
        .explanation-container { margin: auto; max-width: 50ch; }
        .test-btn {
          margin: 1rem auto;
        }
        h2 { font-size: 1.5rem; }
        .desktop-only { display: none; }
        .info-text { font-weight: 300; font-size: 18px; line-height: 24px; max-width: 90%; }
        p:first-child { margin: 1rem auto; }
        p { margin: 1rem auto; }
        .test-btn { margin: 1rem; }
        .info-text-desktop { font-size: 12px; padding: 0 0.25rem 0 0; margin: 0.5rem 0.25rem 0; position: relative; bottom: 0; align-self: center; }
      }
    }

    .details .form, .pre-additional-questions-details .form { width: 85%; }
    .assessment .steps {
      .slider-container .slider-labels .label {
        p { font-size: 1.1rem; }
        p.desktop { display: none; } 
        p.mobile { display: block; }
      }
      .progress-bar .bar-section .section-title { font-size: 17px; }
    }

    .halfway .progress-one-txt { font-size: 24px; }

    .assessment-additional { min-height: 350px; }
    .assessment-additional .steps {
      .progress-indicator { color: #202945; font-weight: 700; margin: 2rem auto; }
      .slider-container .slider-labels .label p { font-size: 1.1rem; }
      .slider-container .slider-labels .label p.desktop { display: none; }
      .slider-container .slider-labels .label p.mobile { display: block; }
      .question { font-size: 1.35rem; min-height: 60px; margin: 2rem auto; }
    }

    .results h1, .dashboard h1 { max-width: 80%; font-size: 28px !important; }
    .results .result-container { max-width: 100%;
      .result-info { max-width: 85%; }
      .result-sections {
        width: 65%;
        .score-section { flex-direction: column; align-items: flex-start;
          // .result-section-title { width: 50%; }
          .result-section-title { width: 80%; }
          // .score-progress-bar { width: 45%; margin-left: 3ch; }
          .score-progress-bar { width: 85%; margin-left: 3ch; }
        }
      }
    }
    .results .result-container { display: flex; margin: auto; padding: 2rem; max-width: 100%; flex-direction: column; justify-content: center; align-items: center; padding: 1rem; }

    .invite .form { width: 60%;}
    .invite .invite-btn-container { 
      display: block; width: auto; height: auto; font-family: "lato", sans-serif; text-align: center; margin: 2rem auto 1rem auto;
      .invite-another { 
        display: inline-block; position: relative; top: 0; right: auto; left: auto; width: auto; padding: 0;

        .add-person-btn { display: inline-block; margin: 0 1rem; text-align: center; width: auto; padding: 0.5rem 1rem; font-size: 21px; font-weight: 700; }
      }
      .cta-btn { display: inline-block; margin: 0 1rem; text-align: center; width: auto; padding: 0.5rem 1rem; font-size: 21px; height: unset; }
    }
    .invite-container {
      .schedule-call {
        h3 { font-size: 28px; }
        p { font-size: 20px; line-height: 36px; }
      }
      .invite-reasons .reason { font-size: 19px; }
    }
    .dashboard .selection-form { & { font-size: 24px; }
      .form-details-text .MuiInput-root { font-size: 24px; }
    }
  }

  main .dashboard .dashboard-video {
    & { margin-top: 2rem;}
    .video { & {width: 100%; margin: auto; border-radius: 0;}
      video { width: 100%; height: auto; padding: 1rem; }
     }
  }
  
  main .start .video video { width: 120%; margin: 0 -10%; }
  main .start .video .thumbnail { width: 100%; height: auto; }
  
  main .dashboard table { font-size: 15px; }
  // main .dashboard .details-container { padding: 0 0 15px; }
  main .dashboard .answers-container { 
    & { display: block; margin: auto; } 
    .most-answered { width: auto; margin: 1rem auto; }
  }
  
  main .testimonial-invite { 
    & {font-size: 1.35rem; padding: 2rem; }
    p { max-width: 40ch; margin: 3rem auto 1rem auto; }
  }
  main .dashboard {
    .result-text { width: 95%; }
    .bar-container { padding: 0 15px 30px; }
    .bar-container .result-sections {
      & {width: 65%; margin: 3rem auto 1rem auto; }
      .score-section { 
        & { position: relative; flex-direction: column; align-items: flex-start; }
        .user-amount { & { display: inline-block; position: absolute; min-width: 30px !important; top: 5px; right: calc(18.5% - 30px); margin-left: 0; text-align: unset; }
          .number { position: absolute; left: 16px; top: -10px; }
        }
        // .result-section-title { width: 50%; }
        .result-section-title { width: 80%; }
        // .score-progress-bar { width: 45%; margin-left: 3ch; }
        .score-progress-bar { width: 80%; margin-left: 3ch; }
      }
    }
    .urgency { margin: 1rem auto; }
    .alignment .tab-buttons {
      button { font-size: 16px; padding: 15px 10px 20px; width: auto; }
      .active { padding: 15px 10px; }
    }
  }
  main .results .result-sections { & {} 
    .individual-scores-container { & { width: 90%; margin: 45px auto 45px auto; }
    .score-section { position: relative; flex-direction: column; align-items: flex-start; width: 90%; margin: 0 0 0 auto; margin-left: 10%; }
    .score-section .score-progress-bar { width: 70%; margin-left: 3ch; }
  }}
  // main .dashboard .bar-container .result-sections .score-section .score-progress-bar { width: 80%; margin-left: 3ch; }

}

@media only screen and (min-width: 650px) {

  main .dashboard .urgency .urgency-slider .slider-labels .label p br { content: ""; }
  main .dashboard .urgency .urgency-slider .slider-labels .label p br:after { content: " "; }
}

@media only screen and (max-width: 650px) {

  header {
    .logo-left { img { height: 28px; width: auto; padding: 0 5px 0 0; }}
    .assessment-title { & { margin: 0px auto; padding: 1%; grid-template-columns: 0.25fr 1fr 0.25fr; font-size: 21px; }
      p { font-size: 15px; line-height: 22px; max-width: 28ch; letter-spacing: 2.5px; }
      img { margin: 0 0 0 20px; height: 46px; width: 164px; }
      // p { margin-right: 25px; }
    }
  }
  main .how-it-works .how-it-works-explanation .explanation-container { max-width: 90%; }
  main .assessment .steps { width: 92%; padding: 0 4%; }
  main .start .intro h1 { text-align: center; font-size: 1.75rem; }
  main .start .intro h2 { text-align: center; font-size: 1.25rem; line-height: 25px; }
  main .how-it-works .how-it-works-explanation {
    h2 { font-size: 21px; }
    p { font-size: 16px; }
  }
  main .assessment .steps .progress-bar .bar-section .progress-percentage { font-size: 1rem; margin: 1rem 0 0.5rem 1rem; color: $color1; }
  main .assessment-additional .steps .question { font-size: 21px; padding: 0 10px; }
  
  main .assessment .steps .slider-container .slider-labels .label p { font-size: 1rem; }
  main .assessment .steps .slider-container .slider-labels .label p.desktop { display: none; }
  main .assessment .steps .slider-container .slider-labels .label p.mobile { display: block; font-size: 0.85rem; }
  
  main .assessment-additional .steps h1 { max-width: 30ch; word-break: break-word; margin: auto; font-size: 16px; }
  main .assessment-additional .steps .slider-container .slider-labels .label p { font-size: 0.85rem; }
  main .assessment-additional .steps .slider-container .slider-labels .label p.desktop { display: none; } 
  main .assessment-additional .steps .slider-container .slider-labels .label p.mobile { display: block; }
  main .assessment-additional .steps .slider-container { padding: 1rem 0 3rem 0; }
  main .assessment-additional .steps .step-buttons { padding: 1.75rem 0; }

  main .details .details-container .buttons { & { display: flex; flex-direction: column-reverse; }
  // main .details .details-container .buttons { & { display: flex; max-width: 100% !important; }
    .back-btn { padding: 0; margin: 15px 30px 30px 15px; align-self: flex-start; }
    .next-btn { margin: 15px 30px 30px 25px; }
    .show-results { & { margin: 15px auto; width: 100%; }
      button { display: block; width: 100%; max-width: 300px; margin: 0 auto; padding: 8px 16px; }
    }
  }

  main .details, main .pre-additional-questions-details {
    h1 { font-size: 28px; }
    .form { & { width: 90%; } 
      .form-detail { & { flex: 0 1 100%; max-width: 100%; }
        .input-title { max-width: 100%; }
      }
    }
  }

  main .results .result-container .result-sections { width: 80%; }

  main .invite-container { font-size: 1.1rem; margin: 0 auto 15px;
    .invite-container-spacer { padding: 1rem; 
      h3 { font-size: 24px; line-height: 43px; max-width: 18ch; margin: 1.5rem auto; }
      .invite-reasons {
        flex-wrap: wrap; padding: 20px 0 20px;
        
        .reason { flex: 0 1 100%; font-size: 18px; max-width: 32ch; margin: 30px auto 30px auto; }
        // .reason span { color: $color2; }
        // .reason img { background-color: $colorbg; } 
      }
    }
    .schedule-call { & {}
      h3 { font-size: 24px; line-height: 28px; }
    }
  }
  main .testimonial-container .testimonial { & { color: #fff; margin: 20px auto 0; text-align: center; }
    .quotation-mark { color: $color1; font-size: 46px; }
    .testi { font-size: 1.25rem; }
    .designation { color: $color2; 
      span { color: #fff; }
    }
  }

  main .testimonial-invite {
    & { background: #DCDBDB; margin: 0 auto; padding: 3rem 2rem; color: #27282A; border-radius: 10px; font-size: 21px; font-weight: 700; }
    p { max-width: 40ch; margin: 1rem auto; line-height: 43px; }
    .cta-btn { margin: 2rem auto 0rem auto; }
  }

  main .source-section { & { padding: 30px 20px; max-width: 100%; }
    .source-info { & { margin: 0; padding: 0; background-color: #D9F0FF; }
      p { max-width: 46ch; margin: 15px auto 30px auto; font-size: 21px; font-weight: 700; text-align: center; color: #27282A; line-height: 40px; }
      .cta-btn { & { margin: 0 auto; }
        button { font-size: 21px; font-weight: 700; }
      }
    }
  }
  main .team-container { & { font-size: 18px; padding: 0 10px; } h3 { font-size: 24px; padding: 0 15px; }}

  main .invite {
    h1 { font-size: 24px; max-width: 18ch; padding: 20px 10px 15px; width: auto; }
    p { font-size: 1.2rem; max-width: 40ch; }
  }
  main .results .hurdles-enablers ul, main .dashboard .hurdles-enablers ul { padding-left: 30px; }
  main .dashboard { & { padding: 15px 10px; }
    .container { margin-bottom: 0; }
    .dashboard-video { padding: 0; }
    .hurdles-enablers { margin: 1rem auto; max-width: 95%; }
    .alignment .tab-buttons { & { flex-wrap: wrap; display: grid; grid-template-columns: 1fr 1fr; padding: 8px 12px; }
      button { width: 100%; max-width: 100%; }
      .active { border-radius: 5px; }
    }
  }
  main .dashboard .details-container { & { padding: 0 0rem 1rem; margin-top: 10%; }
    h2 { margin-top: 0; }
  }

  main .dashboard .bar-container { 
    .result-info { max-width: 100%; font-size: 18px; }
    .result-sections {
      width: 85%; margin: 3rem auto 1rem auto;
    } 
}

  main .dashboard {
    h2.dashboard-video-header { font-size: 24px; font-weight: 700; margin: 50px auto 0 auto; }
    .dashboard-video-title { font-size: 18px; }
    .dashboard-video-subtext { font-size: 18px; font-weight: 700; max-width: 50ch; text-align: center; margin: 3rem auto; }
  }

  main .results .tab-change .tab-btn-container .section-name, main .dashboard .tab-change .tab-btn-container .section-name { font-size: 16px; max-width: 21ch; }
  main .results .tab-change, main .dashboard .tab-change { & { margin: 5px auto 15px; width: 100%; }
    p { font-size: 14px; }
  }

  main .results .result-sections { & {} 
    .individual-scores-container { & { width: 100%; margin: 45px auto 35px auto; box-sizing: border-box !important; }
    .score-section { width: 100%; margin: 10px auto; margin-left: 4%; }
    .score-section .score-progress-bar { width: 75%; margin-left: 3ch; }
  }}
  main .results {
    .result-intro { font-size: 18px; padding: 5px 20px; }
    .result-text { font-size: 18px; }
  }

  // main .start .intro h1 br { content: ""; }
  // main .start .intro h1 br:after { content: " "; }

  main .results {
    h1 { font-size: 30px; padding: 24px 16px 16px; }
    .progress-bar { width: 90%; position: relative; margin: 75px auto 100px; }
    .result-sections .score-section { font-weight: 400; }
    // .pervalue h4.nimp1:after { content: '0%'; position: absolute; right: -19px; bottom: -15px; }
    // .pervalue h4.nimp:after { content: '45%'; position: absolute; right: -19px; bottom: -15px; }
    // .pervalue h4.mexpe:after { content: '70%'; position: absolute; right: -19px; bottom: -15px; }
    // .pervalue h4.eexpe:after { content: '100%'; position: absolute; right: -19px; bottom: -15px; }

    .pervalue-text { & { margin: 10px auto; position: relative; width: 100%; }
      p { display: inline-block; text-align: center; margin: 0 auto; font-size: 12px; color: #4A4A4A; }
    }
    .score-section-container {
      & { flex-wrap: wrap; padding: 0 0 20px 0; max-width: 90%; margin-top: 0; }
      .score-section { 
        // & { margin: 10px auto; min-width: 200px; width: 75%; max-width: 350px; }
        & { margin: 10px auto; flex: 0 1 50%; max-width: 50%; }
        .score-txt { font-size: 18px; font-weight: 700; margin: 0.25rem auto; }
        .score-name { font-size: 18px; font-weight: 400; margin: 0.25rem auto; }
        .score-slider { width: 90%; margin: 0 auto; margin-bottom: 16px; }
        
      }
    }
  }
  main .invite-container { font-size: 1.1rem; color: white; margin: 0 auto;
    .invite-container-spacer { padding: 1rem; 
      h3 { font-size: 24px; line-height: 43px; margin: 1.5rem auto; padding: 0 15px; }
      .invite-reasons {
        flex-wrap: wrap;
        padding: 20px 0 20px;
        
        .reason { flex: 0 1 100%; font-size: 18px; max-width: 27ch; margin: 2rem auto; }
        .reason span { color: $color1; }
        .reason img { } 
      }
    }
  }
  main .testimonial-container .testimonial { & { color: #fff; margin: 20px auto 0; text-align: center; }
    .quotation-mark { color: $color1; font-size: 46px; }
    .testi { font-size: 18px; line-height: 45px; }
    .designation { color: $color1; 
      span { color: #fff; font-size: 18px; }
    }
  }
  main .details h1, main .pre-additional-questions-details h1 { font-size: 28px; }
  main .testimonial-invite {
    & { background: #DCDBDB; margin: 0 auto; padding: 3rem 2rem; color: #27282A; border-radius: 10px; font-size: 21px; font-weight: 700; }
    p { max-width: 40ch; margin: 1rem auto; line-height: 43px; }
    .cta-btn { margin: 2rem auto 0rem auto; }
  }
  main .dashboard {
    h1 { font-size: 30px; padding: 24px 16px 16px; }
    .score-section-container .score-section .score-name, .score-section-container .score-section .score-txt { font-size: 18px; }
    .progress-bar { & { width: 90%; position: relative; margin: 60px auto 30px; }
      .pervalue-text { & { margin: 10px auto; bottom: -65px; }
        p { font-size: 12px; } }
    }
    .score-section-container { & { display: grid; grid-template-columns: 1fr 1fr; }
      .score-section { width: 95%; }
    }
    .alignment .tab-buttons button { font-size: 16px; padding: 10px 12px; }
    .result-text p { margin: 15px auto; }
  }
  main .invite-legacy-container .invite-legacy { & { padding: 30px 15px; }
    h1 { font-size: 24px; line-height: 43px; margin: 0 auto 15px; padding: 15px 30px 0; max-width: 16ch; }
    p { font-size: 18px; padding: 5px 20px; }
    .cta-btn { margin: 2rem auto 0 auto; }
  }

  main .schedule-container { & {  }
    h3 { font-size: 24px; line-height: 43px; }
    p { font-size: 18px; line-height: 32px; max-width: 28ch; }
    .call-link {
      button { width: 100%; max-width: 280px; }
    }
  }


}

@media only screen and (max-width: 550px) {

  header { & { margin: 0px auto; padding: 30px 10px 15px; grid-template-columns: 0.25fr 1fr 0.25fr; grid-template-columns: 0.15fr 1fr 0.15fr; }
    .assessment-title { font-size: 16px; line-height: 18px; letter-spacing: 2px; }
    .logo img { display: none; }}

  main .container { margin: 0 auto 15px; }
  main .start { & { margin-top: 15px; }
    .intro { h1 { font-size: 21px; line-height: 25px; max-width: 20ch; }
      .intro-points p { font-size: 17px; line-height: 24px; }
      h2 { font-size: 16px; line-height: 27px; font-weight: 300; max-width: 29ch; }
      .completion img {margin-top: 2.5px; width: 16px !important; height: 16px !important; }

      .time-estimate{
        span{
          font-weight: 700;
        }
      }
    }
    .video { .close-video { right: 5px !important; top: 5px !important; } }
  }

  main .start .video .owner-details { height: 60px; }

  main .start .video .owner-details .name-mobile { margin: 0; display: block; }
  main .start .video .owner-details .position-mobile { font-weight: 600; display: inline-flex; }
  main .start .video .owner-details .company-mobile { margin-left: 1ch; font-weight: 600; display: block; }

  main .start .video .owner-details .name { display: none; }
  main .start .video .owner-details .position { display: none; }
  main .start .video .owner-details .company { display: none; }

  main .how-it-works {
    padding: 0.25rem;
    .how-it-works-tiles { & { margin: 0.5rem auto; max-height: 360px; max-width: 360px; }
      .how-it-works-btn { min-height: 110px; height: auto; min-width: 100px; width: auto; margin: 0.25rem; font-size: 1rem; }
    }
    // .how-it-works-explanation { width: 90%; }
  }
  main .assessment .steps { & { margin: 0 auto 30px; }
    .question { min-height: 180px; font-size: 20px; max-width: 95%; }
    .progress-bar .bar-section .section-title { font-size: 12px; }
    .slider-container .slider-labels .label { width: 14%; }
    .slider-container .slider-labels .label p.mobile { font-size: 14px; }
    .progress-indicator { margin: 30px auto 30px; font-size: 18px; }
    .progress-bar { & { flex-wrap: wrap; }
      .bar-section { width: 47% !important; }
      .bar-section .bar { height: 6px; margin: 0 5px 0; }
      .bar-section .section-title { font-size: 12px; }
      .bar-section .section-title { font-size: 14px; margin-bottom: 31px; margin-top: 2px; }
    }
    .slider-container .slider-labels { font-size: 14px; }
    // .slider-container .slider-labels .label p { font-size: 1rem; }
    .radio-container .slider-labels { font-size: 14px; }
    .radio-container .slider-labels .label p.desktop { display: none; }
    .radio-container .slider-labels .label p.mobile { display: block; }
  }
  main .assessment-additional .steps { & { width: 95%; margin: 0 auto 30px; }
    .step-buttons { margin: 3rem auto 30px; }
  }
  main .assessment-additional .steps .textarea-field { & { width: 100%; max-width: 100%; }
    .form-details-text { box-sizing: border-box; margin-bottom: 15px; }
  }
  main .halfway {
    .progress-one-txt { font-size: 21px; line-height: 28px; }
    .completion { width: 90%; }
  }
  main .assessment-additional .steps {
    .form-details-text { height: 160px; }
    .question p br { content: ""; }
    .question p br:after { content: " "; }
    .slider-container .slider-labels .label p { font-size: 12px; }
    .step-buttons button, .step-buttons .nextbtn { padding: 10px 15px; }
    .step-buttons .nextbtn.all-set-btn { padding: 12px 16px; font-size: 20px; }
  }
  // main .assessment .steps .slider-container .custom-slider.MuiSlider-root { width: calc(100% + 25px); margin-left: -6px; }
  main .assessment .steps .slider-container .slider-labels .label p { width: 145%; margin-left: -75%; }

  main .details { margin-top: 15px; padding: 0; }
  main .details h1 { font-size: 24px; }
  main .details p { padding: 0 5%; font-size: 18px; }
  main .details p br { content: ""; }
  main .details p br:after { content: " "; }
  main .details .form { width: 90%; }
  main .details .form .form-detail { flex: 0 1 100%; max-width: 100%; margin-bottom: 0.5rem; }
  main .details .form .form-detail .form-details-text { padding: 0; max-width: 100%; }
  main .details .form .form-detail .form-details-text .MuiInput-root { padding: 5px 15px; min-height: 45px; }

  main .details { & { width: 95%; margin-top: 15px; }
    h1, main .pre-additional-questions-details h1 { font-size: 24px; }
    p { padding: 0 5%; font-size: 18px; }
    p br { content: ""; }
    p br:after { content: " "; }
    .form, main .pre-additional-questions-details .form { width: 90%; }
    .form .form-detail .form-details-text { padding-right: 0; max-width: 100%; }
    .details-buttons { & { max-width: 90%; display: flex; flex-direction: column-reverse; }
      .back-btn { align-self: flex-start; margin-top: 2rem; }
      .cta-btn { & { width: auto; }
        button { width: auto; padding: 8px 1.5rem; } }
    }
  }

  main .results { margin-top: 15px; }
  main .results .selection-form { width: 70%; flex-wrap: wrap; }
  main .results .selection-form .form-detail { width: 100%; }
  main .results .selection-form .form-text { width: 100%; margin-bottom: 10px; text-align: center; font-size: 18px; margin-top: 8px; }
  main .results h1 { font-size: 20px !important; line-height: 28px; max-width: 90%; }
  main .results .result-text { & { margin: 20px auto; font-size: 18px; width: 95%; padding: 15px; }
  p { text-align: center; }
}
  // main .results .result-container { & { padding: 0; }
  main .results, main .dashboard { & { padding: 0; }
    .result-info { font-size: 16px; }
    .result-sections { & { width: 95% !important; padding: 0 10px !important; box-sizing: border-box !important; }
      .score-section { flex-direction: row !important; justify-content: center !important; align-items: center !important; }
      .score-section .result-section-title { & { width: 45% !important; } p { font-size: 15px; }}
      .score-section .score-progress-bar { margin-left: 0 !important; width: 85% !important; }
    }
    .hurdles-enablers li { font-size: 16px; }
  }
  main .results .details-container { & { padding: 0; }
    .result-info { font-size: 16px; }
    .result-sections.individual { & { width: 95% !important; }}
    .result-sections { & { width: 100% !important; padding: 0 10px !important; box-sizing: border-box !important; }
      .score-section { flex-direction: row !important; justify-content: center !important; align-items: center !important; margin: 10px auto; }
      .score-section .result-section-title { & { width: 45% !important; } p { font-size: 15px; }}
    }
  }
  main .results .result-sections { margin: 30px auto; padding-bottom: 1rem; }
  main .results .result-sections .score-section { flex: 0 1 100%; margin-bottom: 10px; }
  main .results .result-sections .score-section .score-txt { margin-bottom: 9px; }
  main .results .progress-bar { width: 90%; }
  main .results .si_pro_header h4 { font-size: 13px; }
  // main .results .si_pro_header h4.nimp:before { bottom: 63px; }
  // main .results .si_pro_header h4.mexpe:before { bottom: 63px; }
  main .results .si_ass_progress .msi_score { font-size: 26px; }
  main .results .members-answered { font-size: 16px; }
  main .results .members-answered .answered-detail { font-size: 14px; padding: 10px 15px; width: calc(100% - 30px); }
  main .invite-container {
    p { font-size: 16px; line-height: 32px; }
    .schedule-call { & { margin: 15px auto 60px; }
      h3 { font-weight: 700; font-size: 21px; line-height: 26px; max-width: 28ch; }
      p { font-size: 18px; line-height: 26px; max-width: 30ch; }
    }
    .invite-container-spacer .invite-reasons .reason { & { font-size: 16px; line-height: 24px; }
      .height30 { height: 2px; }
    }
    .invite-reasons .reason img { height: 41px; width: 41px; }
  }
  main .invite-container .cta-btn { width: 90%; margin: 1rem auto; min-width: 210px; }
  main .testimonial-invite { .cta-btn { margin: 2rem auto 0 auto; } }
  main .invite-container p br { content: ""; }
  main .invite-container p br:after { content: " "; }
  main .invite .details-container { .form-hr { display: block; border: none; height: 1px; margin: 2rem 1rem 0 1rem; background: #E4E4E4; } }
  main .invite .invite-btn-container .invite-another { margin-left: 0; margin: 0 auto 1rem auto; }
  main .invite .invite-btn-container .invite-another .add-person-btn { padding: 0.5rem 1rem; margin: 0; font-size: 18px; }
  main .invite .invite-btn-container .cta-btn { padding: 0.5rem 1rem; font-size: 18px; }
  // main .testimonial-invite { p { max-width: 24ch; margin: 1rem auto; } } 
  main .schedule-container { width: 85%; font-size: 18px; }
  // main .testimonial-container { flex-wrap: wrap; padding-top: 12px; }
  // main .testimonial-container .avatar { flex: 0 1 100%; text-align: center; margin-right: 0; }

  main .testimonial-container { flex-wrap: wrap; padding-top: 12px; }
  main .testimonial-container .avatar { flex: 0 1 100%; text-align: center; margin-right: 0; }
  main .testimonial-container .testimonial .cta-btn { & { width: 90%; min-width: 300px; } 
    button { font-size: 1.25rem; }
  }
  main .testimonial-container .testimonial .testi br { content: ""; }
  main .testimonial-container .testimonial .testi br:after { content: " "; }
  main .testimonial-container .testimonial .quotation-mark { font-size: 46px; }
  main .testimonial-container .testimonial .designation { font-size: 26px; }
  main .testimonial-container .testimonial .designation span { padding-top: 6px; }

  main .results { width: 95%; margin-top: 15px; }
  main .results .selection-form { width: max-content; max-width: 95%; flex-wrap: wrap; }
  main .results .selection-form .form-detail { width: 100%; margin-right: 0; }
  main .results .selection-form .form-text { width: 100%; margin-bottom: 10px; text-align: center; font-size: 18px; margin-top: 8px; }
  main .results h1 { font-size: 24px; padding: 1.5rem 1rem 1rem; width: auto; }
  main .results .result-text { & { margin: 20px auto; font-size: 18px; }
    p { margin: 15px auto; max-width: 29ch; }
  }
  main .results .result-sections { margin: 50px 5%; padding-bottom: 50px; }
  main .results .result-sections .score-section { flex: 0 1 100%; margin-bottom: 20px; }
  main .invite-container .cta-btn { & { width: 100%; max-width: 90%; } 
    button { font-size: 18px; padding: 8px 16px; line-height: 16px; width: 100%; }}
  main .results .result-sections .score-section .score-txt { margin-bottom: 9px; }
  main .results .progress-bar { & { width: 90%; margin: 60px auto 80px; }
    .pervalue h4::after { font-size: 13px; bottom: 40px; }
    .pervalue h4.eexpe:after { right: -10px; }
  }
  main .results .si_pro_header h4 { font-size: 13px; }
  // main .results .si_pro_header h4:before { bottom: -83px !important; }
  // main .results .si_pro_header h4.nimp:before { bottom: 63px; }
  // main .results .si_pro_header h4.mexpe:before { bottom: 63px; }
  main .results .si_ass_progress .msi_score { font-size: 26px; }
  main .results .members-answered { width: 95%; font-size: 16px; }
  main .results .members-answered .answered-detail { font-size: 14px; padding: 10px 15px; width: calc(100% - 30px); }
  main .results .score-section-container { & { flex-direction: row; }
    .score-section { width: 100%; max-width: 75%; }
  }

  main .invite-container { max-width: 95%; font-size: 18px; padding: 0 15px 30px; }
  main .invite-container p br { content: ""; }
  main .invite-container p br:after { content: " "; }
  main .invite-container .invite-container-spacer h3 { font-size: 21px; line-height: 28px; }
  main .invite-container .invite-reasons { flex-wrap: wrap; padding: 20px 0 20px; }
  main .invite-container .invite-reasons .reason { flex: 0 1 100%; font-size: 18px; margin-bottom: 50px; margin-top: 20px; }

  main .team-container { width: 90%; font-size: 18px; }
  main .team-container h3 { font-size: 24px; line-height: 38px; }

  main .schedule-container { width: 85%; font-size: 18px; }
  main .schedule-container h3 { font-size: 24px; }
  // main .testimonial-container { flex-wrap: wrap; padding-top: 12px; }
  // main .testimonial-container .avatar { flex: 0 1 100%; text-align: center; margin-right: 0; }

  main .testimonial-container { flex-wrap: wrap; padding-top: 12px; }
  main .testimonial-container .avatar { flex: 0 1 100%; text-align: center; margin-right: 0; }
  main .testimonial-container .testimonial .quotation-mark { font-size: 46px; }
  main .testimonial-container .testimonial .designation { font-size: 26px; }
  main .testimonial-container .testimonial .designation span { padding-top: 6px; }

  main .invite .invite-another-person .invite-another { & { top: 25px; right: -26px; left: 0; margin-left: -18%; }
  .add-btn { font-size: 18px; }
  }
  main .invite .cta-btn { margin-top: 20px; margin-right: 12%; width: 164px; min-width: 160px; padding: 4px 0; width: auto; padding: 4px 15px; font-size: 20px; font-size: 18px; }
  main .thank-you { width: 95%; }
  main .thank-you h1 { font-size: 24px; max-width: 24ch; }
  main .thank-you p { width: 94%; margin: 25px 3% 35px; font-size: 18px; }
  main .testimonial-container .testimonial { font-size: 18px; width: 90%; margin: 20px auto 0; text-align: center; }



  main .assessment-additional .steps .textarea { min-height: 120px; }
  // main .assessment-additional .steps .textarea-field { margin-bottom: -77px; }
  main .invite { margin-top: 15px; }
  // main .invite h1 br { content: ""; }
  // main .invite h1 br:after { content: " "; }
  main .invite p.invite-info { margin: 10px auto 45px; font-size: 18px; }
  // main .invite p br { content: ""; }
  // main .invite p br:after { content: " "; }
  main .invite .form { width: 70%; }
  main .invite .form .form-detail { flex: 0 1 100%; }
  main .invite .form .form-detail .invite-field { max-width: 95%; }
  main .invite .form .form-detail .error-text { bottom: auto; }
  main .invite .form .invite-number { left: -25px; top: 25px; }
  main .invite .form .delete-btn { right: auto; left: -32px; top: 64px; }

  main .invite .cta-btn { margin-top: 20px; margin-right: 15%; width: 164px; padding: 4px 0; }
  main .thank-you { 
    min-height: 320px;
    .tick { padding: 3rem 2rem 2rem 2rem; } 
    h1 { padding: 0 2rem; } 
  }
  main .thank-you h1 { font-size: 24px; max-width: 22ch; }
  main .thank-you p { width: 94%; margin: 25px 3% 35px; font-size: 18px; }
  
  main .MuiSwitch-thumb { background-color: #F7D54E !important; }
  main .MuiSwitch-thumb:after { background-color: transparent!important; border: 3px solid #fff; border-radius: 50%; content: ""; height: 3px; left: 50%; padding: 2px; position: absolute; top: 50%; -webkit-transform: translate(-50%,-50%); transform: translate(-50%,-50%); width: 3px;
}
  main .MuiSwitch-track { background-color: #E7E9EB !important; opacity: 1 !important; }
  main .individual-group { display: none; }
  main .toggle-individual-group { display: block; margin: 15px auto; display: flex; align-items: center; justify-content: center; color: #fff; font-size: 21px; }
  main .toggle-individual-group .left { flex: 0 1 25%; text-align: right; }
  main .toggle-individual-group .right { flex: 0 1 35%; }
  main .toggle-individual-group .active { font-weight: 700; color: $color1; }
  main .dashboard { margin-top: 15px; }
  main .dashboard h1 { font-size: 20px !important; padding: 15px 10px 0; }
  main .dashboard .mobile_scores { display: block; }
  main .dashboard .result-text { margin: 15px auto; font-size: 18px; width: auto; max-width: 95%; text-align: center; padding: 5px 15px; }
  main .dashboard .selection-form { & { font-size: 18px; }
      .form-details-text .MuiInput-root { font-size: 24px; }
    }
  main .dashboard .result-sections { margin: 50px 5%; padding-bottom: 0; }
  main .dashboard .result-sections .score-section { flex: 0 1 50%; }
  main .dashboard .result-sections .score-section .score-txt { margin-bottom: 10px; }
  main .dashboard .progress-bar { width: 95%; }
  main .dashboard .si_pro_header h4 { font-size: 13px; }
  main .dashboard .si_pro_header h4.nimp:before { bottom: 55px; }
  main .dashboard .si_pro_header h4.mexpe:before { bottom: 55px; }
  main .dashboard .si_ass_progress .msi_score { font-size: 26px; }
  main .dashboard hr { display: none; }
  main .dashboard table { font-size: 13px; display: none; }
  main .dashboard table .footer .church-average { font-size: 13px; }
  main .dashboard table .header .center-cell { font-size: 0; }
  main .dashboard .urgency h2 { text-align: center; }
  main .dashboard .urgency .urgency-slider h3 { text-align: center; left: 0; width: 100%; }
  main .dashboard .urgency .slider-points .top-points { font-size: 13px; }
  main .dashboard .urgency .urgency-slider .slider-labels { font-size: 12px; }
  main .dashboard .most-answered { font-size: 16px; }
  main .dashboard .most-answered .most-answered-detail { font-size: 14px; }
  // main .dashboard .alignment { width: 90%; }
  main .dashboard .alignment .tab-buttons { & { flex-wrap: wrap; padding: 8px 12px; }
    button { font-size: 16px; min-width: 55px; padding: 10px 5px; flex: 0 1 50%; }
    .active { border-radius: 5px; padding: 10px 5px; }
  }
  main .dashboard .alignment .content .align-details { font-size: 16px; }
  main .dashboard .alignment .content .align-details .answered-detail p { flex: 0 1 90%; }
  main .dashboard .alignment .content .align-details .answered-detail .more-toggle { flex: 0 1 8%; }
  main .dashboard .dashboard-video {
    .video { video { width: 99%; height: auto; padding: 1rem; } }
  }
  main .dashboard .urgency .urgency-slider { margin: 0; background: transparent; }
  main .dashboard .urgency .custom-slider.MuiSlider-root { color: transparent; }
  main .dashboard .urgency .custom-slider .MuiSlider-track { border: 0px; }
  main .dashboard .urgency .MuiLinearProgress-root.custom-bar { background-color: #AFD9FF; height: 8px; margin-top: -22px; margin-left: -5px; }
  main .dashboard .urgency .slider-points .point { width: 8px; height: 8px; margin-top: -2px; margin-left: -5px; }

  main .results .selection-form .form-details-text .MuiInput-root { font-size: 24px; }
  main .dashboard .alignment .content .align-details.analyze .answered-detail { flex-direction: row-reverse; }
  main .dashboard .bar-container { 
    & { padding: 15px 0.25rem 15px; }
    .result-sections {
      & {width: auto; margin: 45px auto 15px auto; }
    }
  }
  main .dashboard {
    .pervalue {
      // h4.nimp1:after { content: "0%"; position: absolute; right: -19px; font-weight: 700; font-size: 24px; }
      h4.nimp:before { left: 0; bottom: -16px; font-size: 13px;}
      h4.nimp:after { right: -19px; bottom: -16px; font-size: 13px;}
      h4.mexpe:after { right: -19px; bottom: -16px; font-size: 13px;}
      h4.eexpe:after { right: -4px; bottom: -16px; font-size: 13px;}
    }
    .si_pro_header h4 { margin-top: 15px; }
    .si_pro_header h4::before {  }
  }
}

@media only screen and (max-width: 550px) {

  // main .start .intro .completion { flex-wrap: wrap; margin: 48px auto 10px; width: 100%; justify-content: center;  }
  // main .start .intro .completion .cta { width: 90%; margin: auto 15% 30px; }

}

@media only screen and (max-width: 450px) {

  // main .dashboard table { font-size: 11px; }
  // main .dashboard table .footer .church-average { font-size: 11px; }

}

// Tabs CSS


// For PDF Export 

#root.pdf-export-results {
  background-color: #00558C;
  max-width: max-content;
  margin: 0 auto;
  height: max-content;
  height: 1650px;
  #pdf-conversion {
    padding-top: 1rem;
    max-width: 100%;
    // max-height: 890px;
    margin: 0 auto;
    height: auto;
    // .container { max-width: 100%; }

    // new custom bar graphs for PDF Export 
    .non-pdf-result-sections { display: none; }
    .pdf-result-sections { display: block; }
    .custom-pdf-bar { width: 100%; background-color: #e5e5e5; height: 25px; position: relative; }
    .custom-pdf-bar-progress { background-color: #447FA6; padding: 0; height: 25px; // display: flex; align-items: center; 
    }
    .first-result-title .custom-pdf-bar-progress { background-color: $color1; padding: 0; height: 25px; }
    header { margin: 2.5rem auto 3.5rem auto; }
    .invite-container { display: none; }
    main .container { border-radius: 0; padding-bottom: 440px; padding-top: 30px; }
    main .results .result-container { padding: 0; }
    #pdf-convert-btn { display: none; }
    .team-container { display: none; }
    footer {
      & { padding: 1rem; }
      .footer-logos-div {
        & { max-width: 80%; }
        .footer-logo-right { 
          & { max-width: 64px; max-height: 64px; font-size: 1rem; } 
          img { max-width: 64px; width: 58px; height: 66px; max-height: 70px; }
        }
      } 
    } 
  }
}


#root.pdf-export-dashboard {

  font-family: "lato", sans-serif !important;
  background-color: #00558C;
  max-width: max-content;
  margin: 0 auto;
  height: max-content;
  // height: 1650px;
  // height: 2900px;
  height: auto;
  #pdf-conversion {
    // padding-top: 1rem;
    max-width: 100%;
    // max-width: 1200px;
    // max-height: 890px;
    margin: 0 auto;
    height: auto;
    width: 130ch;
    width: 1200px;
    // width: 1150px;
    // .container { max-width: 100%; }
    // .invite-container { display: none; }

    // new custom bar graphs for PDF Export 
    .non-pdf-result-sections { display: none; }
    .pdf-result-sections { display: block; }
    .custom-pdf-bar { width: 100%; background-color: #e5e5e5; height: 25px; position: relative; }
    .custom-pdf-bar-progress { background-color: #447FA6; padding: 0; height: 25px; // display: flex; align-items: center; 
    }
    .first-result-title .custom-pdf-bar-progress { background-color: $color1; padding: 0; height: 25px; }

    // new custom slider for PDF export 
    // .new-slider-header { font-family: "lato", sans-serif !important; font-size: 18px; text-transform: uppercase; text-align: center; }
    // .new-slider-container { position: relative; padding: 0px 0px 20px 0px; padding: 30px 50px; background-color: #DCE9F5; border-radius: 10px; max-width: 80%; max-width: 840px; margin: 0 auto; }
    // .custom-html-slider { & { position: absolute; width: 735px; height: 150px; height: 183px; background-color: #DCE9F5; padding: 32px 88px; padding: 0; margin: auto; }
    //   .urgency-subtitle { position: absolute; top: -20px; left: 0; font-family: "lato", sans-serif !important; }
    //   .custom-slider-container {
    //     & { width: 735px; margin: auto; position: absolute; bottom: 50px; }
    //     .slider-line { width: 100%;  height: 2px; background-color: #182036; margin: auto; }
    //     .slider-progress { position: relative; top: -10px; height: 2px; height: 0px; margin: 0; border: 9px solid #4389C960; border-radius: 25px; }
    //   }
    //   .points-and-labels { position: relative; }
    //   .slider-points .score { font-family: "lato", sans-serif !important; top: 93px; color: #ffffff; background-color: #4C88AE; width: 30px; height: 30px; border: 5px solid #4C88AE; border-top: 8px solid #4C88AE; border-bottom: 3px solid #4C88AE; border-radius: 50%; z-index: 9; text-align: center; font-weight: 700; }
    //   .point { position: absolute; height: 0; width: 0; font-size: 0; }
    //   .point span { position: absolute; font-size: 14px; height: 14px; width: 14px; background-color: #4C88AE; color: transparent; bottom: -80px; border-radius: 50%; }
    //   .top-points { position: absolute; display: inline-block; top: 0px; font-family: "lato", sans-serif !important; font-weight: 700; }
    //   .top-labels { position: relative; display: inline-block; width: 1px; height: 35px; top: 25px; background-color: #000; font-weight: 400; }
    //   .label { position: absolute; display: inline-block; font-size: 14px; margin: 0 auto; text-align: center; bottom: -115px; left: 0; 
    //     p { font-family: "lato", sans-serif !important; }
    //   }
    //   .slider-labels { & { position: relative; }
    //     .label { position: relative; display: inline-block; font-size: 14px; margin: 0 auto; text-align: center; bottom: -60px; 
    //     }
    //   } 
    // }
  
    main .dashboard .bar-container .result-sections .score-section {
      display: flex !important; flex-direction: row; justify-content: center; align-items: center; font-size: 18px !important; // margin: 5px auto;
      .result-section-title { font-family: "lato", sans-serif !important; 
        // width: 28ch !important; 
        width: 22% !important; 
      }
    }

    main .dashboard .bar-container .result-sections .score-section.first-result-title { 
      .result-section-title p { & { color: $color1 !important; }
        span { color: $color1 !important; }
      }
    }
    
    main .dashboard .bar-container .result-sections .score-section .result-section-title p { font-family: "lato", sans-serif !important; 
      // font-size: 1rem !important; 
      font-size: 18px !important; 
      span { font-size: 18px !important; }
    }
    main .dashboard .bar-container .result-sections .score-section .score-inside-progress-bar { font-family: "lato", sans-serif !important; top: -25px !important; }
    main .dashboard .bar-container .result-sections .score-section .user-amount .number { font-family: "lato", sans-serif !important; font-size: 14px !important; }
    header { margin: 2.5rem auto 1.5rem auto; padding-top: 1.5rem; padding-bottom: 0.5rem; }
    main .pdf-btn { padding: 0; display: none; }
    main .container { border-radius: 0; padding-bottom: 10px; padding-top: 10px; margin: 0 auto; }
    main .dashboard { 
      font-family: "lato", sans-serif !important; width: 130ch; width: auto;
      // padding: 1rem 2.5rem; 
      h1 { padding: 1rem 0 0; }
      // h1.result-title { margin: 0 auto 1rem auto; }
      // .result-sections {  margin: 1.5rem 2%; }
      .details-container { padding: 0; margin: 0 auto; }
      .dashboard-video-header { display: none; }
      .dashboard-video-title { display: none; }
      .dashboard-video { display: none; }
      .dashboard-video-subtext { display: none; }
      .cta-btn-learn { display: none; }
      .urgency { display: none; }
      // .new-slider-header { display: block; }
      // .new-slider-container { display: block; }
      .new-slider-header { display: none; }
      .new-slider-container { display: none; }
      .hurdles-enablers { display: none; }
      .bar-container { page-break-after: always; padding: 0;
        // min-height: 190mm; min-height: 140mm; 
      }
      .bar-container .result-sections .score-section .user-amount {
        right: 16%;
        .number {
          position: relative; left: -5px; top: -10px;
        }
      } 
      .answers-container { page-break-before: always; min-height: 113mm; min-height: 460px; margin: 0 auto; justify-content: center; 
        margin-bottom: 275px; 
        margin-bottom: 150px; 
      }
      .most-answered { & { width: 50%; width: 42ch; width: 460px; margin: 1rem 0.5rem; max-width: 50%; } 
        .green { font-size: 18px; font-family: "lato", sans-serif !important; }
        .red { font-size: 18px; font-family: "lato", sans-serif !important; }
        .most-answered-detail { font-size: 18px; font-family: "lato", sans-serif !important; }
      } 
    }
    #pdf-convert-btn { display: none; }
    
    footer {
      & { padding: 1.5rem 1rem; background-color: #00558C;
        // position: relative; top: 180mm; 
      }
      .footer-logos-div {
        & { max-width: 80%; }
        .footer-logo-right { 
          & { max-width: 64px; max-height: 64px; font-size: 1rem; } 
          img { max-width: 64px; width: 52px; height: 60px; max-height: 70px; }
        }
      } 
    } 
  }

  // Limit Dashboard PDF to 1 Page Changes 

  main .dashboard { & { max-width: 1200px; }
    .bar-container  { box-sizing: border-box; max-width: 1200px; }
    .answers-container  { max-width: 1200px; max-width: 1100px; }
    .details-container  { box-sizing: border-box; max-width: 1200px; }
  }

  // header .assessment-title { font-size: 1.75rem !important; }
  header .assessment-title { font-size: 28px !important; }
  header .assessment-title img { height: 38px; width: 38px; }
  main .dashboard h1 { font-size: 1.35rem; font-size: 32px; }
  // main .dashboard h1.result-title { font-size: 1.35rem; }
  main .dashboard .bar-container .result-info { max-width: 70ch; font-size: 20px; 
  }
  // main .dashboard .urgency .urgency-slider { padding: 115px 100px 90px; border-radius: 8px; margin: 0 auto 16px; }
  main .dashboard .answers-header { display: block; font-size: 18px; font-size: 20px; text-align: center; margin: 1rem auto; font-family: "lato", sans-serif !important; }
  main .dashboard .bar-container .result-sections .score-section .score-progress-bar { width: 45% ;}
  main .dashboard .most-answered .most-answered-detail { padding: 10px 30px; }

}


// .tabs {
//   padding: 15px 0px;
//   text-align: center;
//   color: white;
//   width: 100%;
  
//   cursor: pointer;

//   box-sizing: content-box;
//   position: relative;
//   outline: none;
// }
// .active-tabs {
//   color: #000;
//   background: $color1;
//   border-bottom: 1px solid transparent;
//   &::before {
//     content: "";
//     display: block;
//     position: absolute;
//     top: -5px;
//     left: 50%;
//     transform: translateX(-50%);
//     width: calc(100%);
//     height: 0px;
//     background: rgb(88, 147, 241);
//   }
// }
// button {
//   border: none;
// }

